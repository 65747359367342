import React, { useMemo } from 'react';
import { Badge } from 'react-native-paper';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import { StyleSheet } from 'react-native';

type ChatRoomUnreadMessageCountPropTypes = { unreadMessageCount: number };

export const ChatRoomUnreadMessageCount = ({
  unreadMessageCount,
}: ChatRoomUnreadMessageCountPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);

  return (
    <Badge size={24} style={styles.badge}>
      {unreadMessageCount > 99 ? '99' : unreadMessageCount}
    </Badge>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    badge: {
      position: 'absolute',
      left: 36,
      bottom: 0,
      zIndex: 2,
      backgroundColor: theme.customColors.primary,
      color: theme.customColors.pureWhite,
      borderWidth: 1,
      borderColor: theme.customColors.borders,
      fontWeight: '700',
    },
  });
