import { MaterialCommunityIcons } from '@expo/vector-icons';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface UnavailableFeatureModalStore {
  isVisible: boolean;
  setVisible: (isVisible: boolean) => void;
  description: string;
  isAppStoreLinksIncluded: boolean;
  icon: keyof typeof MaterialCommunityIcons.glyphMap;
  isButtonDisplayed: boolean;
  setUnavailableFeatureModalAsNoPermissionToViewForm: () => void;
  setUnavailableFeatureModalDefault: () => void;
  setUnavailableFeatureModalAsUnknownError: () => void;
  setUnavailableFeatureModalAsNoPermissionToViewInfoPage: () => void;
  reset: () => void;
}

export const useUnavailableFeatureModalStore = create<UnavailableFeatureModalStore>()(
  devtools(set => ({
    isVisible: false,
    setVisible: isVisible => {
      set({ isVisible });
    },
    isAppStoreLinksIncluded: false,
    description: '',
    icon: 'map-marker-question',
    isButtonDisplayed: false,
    setUnavailableFeatureModalAsNoPermissionToViewForm: () => {
      set({
        isAppStoreLinksIncluded: false,
        isButtonDisplayed: true,
        description: `Sie haben nicht die Erlaubnis auf dieses Formular zuzugreifen.
          Wenn Sie glauben, dass dies ein Fehler ist, wenden Sie sich bitte an einen Administrator.`,
        icon: 'account-key',
        isVisible: true,
      });
    },
    setUnavailableFeatureModalAsNoPermissionToViewInfoPage: () => {
      set({
        isAppStoreLinksIncluded: false,
        isButtonDisplayed: true,
        description: `Sie haben nicht die Erlaubnis, diese Infoseite zu lesen.
          Wenn Sie glauben, dass dies ein Fehler ist, wenden Sie sich bitte an einen Administrator.`,
        icon: 'account-key',
        isVisible: true,
      });
    },
    setUnavailableFeatureModalDefault: () => {
      set({
        isAppStoreLinksIncluded: true,
        isButtonDisplayed: false,
        description:
          'Diese Funktion wird im Web nicht unterstützt. \
          Bitte laden Sie die App herunter, um auf diese Funktion zuzugreifen.',
        icon: 'cellphone-arrow-down',
        isVisible: true,
      });
    },
    setUnavailableFeatureModalAsUnknownError: () => {
      set({
        isAppStoreLinksIncluded: false,
        isButtonDisplayed: true,
        description:
          'Es ist ein unbekannter Fehler aufgetreten. \
          Wenden Sie sich bitte an den Support, wenn das Problem weiterhin besteht.',
        icon: 'alert-circle',
        isVisible: true,
      });
    },
    reset: () =>
      set({
        isVisible: false,
        isAppStoreLinksIncluded: false,
        description: '',
        icon: 'map-marker-question',
        isButtonDisplayed: false,
      }),
  })),
);
