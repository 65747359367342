import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface ReloginStore {
  password?: string;
  setPassword: (password: string) => void;
  clear: () => void;
}

export const useReloginStore = create<ReloginStore>()(
  devtools(set => ({
    password: undefined,
    setPassword: (password: string) => set(() => ({ password })),
    clear: () => set({ password: undefined }),
  })),
);
