import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { News } from '../../graphql/operations';

export interface UnreadNewsStore {
  news: News[];
  count: number;
  reset: () => void;
  append: (news: News) => void;
  remove: (newsId: News['id']) => void;
}

const initialItems: News[] = [];

export const useUnreadNewsStore = create<UnreadNewsStore>()(
  devtools(
    (set, get) => ({
      news: initialItems,
      count: initialItems.length,
      reset: () => {
        set({ news: initialItems });
        set({ count: 0 });
      },
      append: news => {
        set({ news: [...get().news, news] });
        set({ count: get().count + 1 });
      },
      remove: newsId => {
        const isNewsFound = !!get().news.find(news => news.id === newsId);
        set({ news: get().news.filter(news => news.id !== newsId) });
        const toSubtract = isNewsFound ? 1 : 0;
        set({ count: get().count - toSubtract });
      },
    }),
    { enabled: __DEV__ },
  ),
);
