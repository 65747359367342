import { useTransition, useCallback, useMemo, useState } from 'react';
import { ExtendedMessage } from '../types';
import { useChatMutations } from './useChatMutations';

export type MessageDeleteModalProps = {
  isVisible: boolean;
  onDismiss(): void;
  onConfirm(): void;
};

export const useDeleteMessage = () => {
  const { deleteMessage, isDeletingMessage } = useChatMutations();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState<ExtendedMessage>();
  const [, startTransition] = useTransition();

  const onConfirm = useCallback(async () => {
    if (!messageToDelete) {
      return;
    }
    setIsConfirmModalVisible(false);
    await deleteMessage(messageToDelete);
  }, [messageToDelete]);

  const showConfirmModal = (message: ExtendedMessage) => {
    startTransition(() => {
      setMessageToDelete(message);
      setIsConfirmModalVisible(true);
    });
  };

  const onDismiss = useCallback(() => setIsConfirmModalVisible(false), []);

  const confirmModalProps: MessageDeleteModalProps = useMemo(() => {
    return {
      isVisible: isConfirmModalVisible,
      onConfirm,
      onDismiss,
    };
  }, [isConfirmModalVisible]);

  return {
    confirmModalProps,
    showConfirmModal,
    isDeletingMessage,
  };
};
