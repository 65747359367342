import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useMemo, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { Text, Chip } from 'react-native-paper';
import { getLocales } from 'expo-localization';
import { RoundedSquareIcon } from '../Icon/RoundedSquareIcon';
import { AppTheme, useAppTheme } from '../../../styles/theme';
import DateTimePickerModal from 'react-native-modal-datetime-picker';

type DateTimeSettingPropTypes = {
  icon?: keyof typeof MaterialCommunityIcons.glyphMap;
  value?: Date;
  label: string;
  unspecifiedValueText?: string;
  setValue: (value: Date | undefined) => void;
};

const formatDate = (date: string | Date, locale = 'de-DE'): string => {
  const dateObject = typeof date === 'string' ? new Date(Date.parse(date)) : date;

  const formattedDate = dateObject.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  const formattedTime = dateObject.toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  return `${formattedDate}, ${formattedTime}`;
};

export const DateTimeSetting = ({
  label,
  value,
  icon,
  unspecifiedValueText = 'Datum festlegen',
  setValue,
}: DateTimeSettingPropTypes) => {
  const theme = useAppTheme();
  const styles = useMemo(() => createStyles(theme), [theme]);
  const { languageTag: locale, languageCode } = getLocales()[0];

  const [datePickerVisibility, setDatePickerVisibility] = useState<boolean>(false);

  const handleConfirm = (date: Date) => {
    setValue(date);
    setDatePickerVisibility(false);
  };

  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.innerLeftContainer}>
          {icon && <RoundedSquareIcon icon={icon} marginRight={6} />}
          <Text style={styles.label} numberOfLines={1}>
            {label}
          </Text>
        </View>
        <View>
          <Chip
            compact
            style={styles.chip}
            textStyle={styles.chipText}
            onPress={() => setDatePickerVisibility(true)}
            onClose={value ? () => setValue(undefined) : undefined}
          >
            {value ? formatDate(value, locale) : unspecifiedValueText}
          </Chip>
        </View>
      </View>
      <DateTimePickerModal
        locale={languageCode as string}
        confirmTextIOS="Bestätigen"
        cancelTextIOS="Abbrechen"
        isVisible={datePickerVisibility}
        mode="datetime"
        display="inline"
        onConfirm={handleConfirm}
        onCancel={() => setDatePickerVisibility(false)}
      />
    </View>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      marginTop: 8,
      marginBottom: Platform.OS === 'ios' ? 8 : 0,
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    innerContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    innerLeftContainer: {
      flex: 1,
      flexDirection: 'row',
      alignSelf: 'flex-start',
    },
    label: { marginTop: 4, fontSize: 14, alignSelf: 'flex-start' },
    date: { fontSize: 14, alignSelf: 'flex-end', fontWeight: 'bold' },
    chip: {
      borderRadius: 100,
      backgroundColor: theme.customColors.background3,
    },
    chipText: { color: theme.customColors.text },
    closeIcon: { position: 'absolute', right: -38, bottom: -6 },
    dateTimePicker: { flexDirection: 'row', marginTop: -4 },
  });
