import * as React from 'react';
import { useEffect, useState } from 'react';
import { Linking, Platform, SafeAreaView, StyleSheet } from 'react-native';
import PDFReader from '@luciapp/rn-pdf-reader-js';
import { Log } from '../../utils/Log';
import { Headline, Portal, useTheme } from 'react-native-paper';
import { themeProp } from '../../types/main';
import { LoadingBlocker } from '../../components/Common/Loading/LoadingBlocker';
import { useNavigation } from '@react-navigation/native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ecf0f1',
    height: 400,
    width: '100%',
  },
});

export const LoginHelpPage = () => {
  const [documentSource, setDocumentSource] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme() as themeProp;
  const navigation = useNavigation();
  const helpPagePath = 'Login_Help_Page.pdf';

  useEffect(() => {
    /* @todo must be awaited */
    void downloadDocument();
  }, []);

  const downloadDocument = async () => {
    try {
      const helpSignedRes = await fetch(
        process.env.EXPO_PUBLIC_API_URL + `getStaticFile?path=${helpPagePath}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
      );
      const helpSignedUrl = await helpSignedRes.json();
      if (helpSignedUrl) {
        if (Platform.OS === 'web') {
          await Linking.openURL(helpSignedUrl);
          navigation.goBack();
        } else {
          setDocumentSource(helpSignedUrl);
        }
      } else {
        setDocumentSource(undefined);
      }
    } catch (err) {
      Log.error(new Error('Unable to download help PDF'), {
        data: err,
      });
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      {documentSource && Platform.OS !== 'web' && (
        <PDFReader
          source={{
            uri: documentSource,
          }}
          withPinchZoom={true}
          withScroll={true}
          customStyle={{
            readerContainerZoomContainer: {
              display: 'none',
            },
          }}
          onError={err => {
            Log.error(new Error('Unable to display help PDF'), {
              data: err,
            });
          }}
          onLoadEnd={() => setIsLoading(false)}
        />
      )}
      <Portal>
        <LoadingBlocker visible={isLoading} spinnerColor={theme.customColors.accent}>
          <Headline
            style={{
              color: '#fff',
            }}
          >
            Dokument wird geladen.
          </Headline>
        </LoadingBlocker>
      </Portal>
    </SafeAreaView>
  );
};
