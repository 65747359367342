import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSignedOtcUrl } from '../../hooks/useSignedOtcUrl/useSignedOtcUrl';
import { useCachedImageV2 } from '../../hooks/useCachedImageV2/useCachedImageV2';
import { UploadType } from '../Common/FileUpload/types';
import { DownloadableImagePlaceholder } from './components/DownloadableImagePlaceholder';
import { ConfirmDialog } from '../Dialog/ConfirmDialog';

export interface ImageLoaderProps {
  renderOnLoad(uri: string): React.ReactNode;
  renderOnError(): React.ReactNode;
  renderWhileLoading(): React.ReactNode;
  storageFilename: string;
  type: UploadType;
  isDownloadConfirmationNeeded?: boolean;
}

export const ImageLoader = ({
  renderWhileLoading,
  renderOnLoad,
  renderOnError,
  storageFilename,
  type,
  isDownloadConfirmationNeeded,
}: ImageLoaderProps) => {
  const { fetchSignedUrl } = useSignedOtcUrl();
  const { isLoading, uri, fetchCachedImage, hasError } = useCachedImageV2();
  const [isDownloadConfirmed, setDownloadConfirmed] = useState(false);
  const [isConfirmDownloadModalVisible, setConfirmDownloadModalVisible] = useState(false);

  const onPressDownload = useCallback(() => setConfirmDownloadModalVisible(true), []);

  const onConfirmDownload = useCallback(() => setDownloadConfirmed(true), []);

  const onCancelDownload = useCallback(() => setConfirmDownloadModalVisible(false), []);

  const isDownloadPermissionNeeded = useMemo(
    () => isDownloadConfirmationNeeded && !isDownloadConfirmed,
    [isDownloadConfirmationNeeded, isDownloadConfirmed],
  );

  useEffect(() => {
    if (isDownloadPermissionNeeded) {
      return;
    }
    void fetchCachedImage({
      storageFilename,
      type,
      signedImageUrlFallback: fetchSignedUrl,
    });
  }, [isDownloadPermissionNeeded, storageFilename, type]);

  if (isDownloadPermissionNeeded) {
    return (
      <>
        <DownloadableImagePlaceholder onPress={onPressDownload} />
        <ConfirmDialog
          isOpen={isConfirmDownloadModalVisible}
          title="Bild herunterladen?"
          onCancel={onCancelDownload}
          onConfirm={onConfirmDownload}
          confirmButtonLabel="Herunterladen"
          content="Sie nutzen momentan mobile Daten. Möchten Sie das Bild trotzdem herunterladen?"
        />
      </>
    );
  }

  if (hasError) {
    return <>{renderOnError()}</>;
  }

  if (isLoading || !uri) {
    return <>{renderWhileLoading()}</>;
  }
  return <>{renderOnLoad(uri)}</>;
};
