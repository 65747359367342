import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { FileOptionalParams, File } from '../components/FileGallery/types';

export interface FileStore {
  files: File<FileOptionalParams>[];
  setFiles: (files: File<FileOptionalParams>[]) => void;
  clear: () => void;
}

export const useFileStore = create<FileStore>()(
  devtools(set => ({
    files: [],
    setFiles: files => set(() => ({ files })),
    clear: () => set({ files: [] }),
  })),
);
