import {
  ChangeTemporaryPasswordMutation,
  ChangeTemporaryPasswordMutationVariables,
  LoginMutation,
  TenantConfigurationQuery,
  useChangeTemporaryPasswordMutation,
  useDeletePushNotificationDeviceTokenMutation,
  useLoginMutation,
} from '../../graphql/operations';
import appConfig from '../../app.config';
import { refetchToken } from '../backendRequests';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { RefreshTokenError } from '../types/RefreshTokenError';
import { clearQueryCache } from '../utils/clearQueryCache';

export const NETWORK_TIMEOUT_MILLISECONDS = 10000;
import { isAuthTokenValid } from '../utils/isAuthTokenValid';
import { Log } from '../utils/Log';
import { ILogOut } from '../types/store';
import { cleanupBiometricStorage } from '../utils';
import { useThemeStore } from './ThemeStore';
import { usePushNotificationStatusStore } from '../utils/PushNotificationStatusStore/PushNotificationStatusStore';
import { Platform } from 'react-native';

const isTokenInvalidErrorMessage = (msg: string) => {
  return (
    msg === RefreshTokenError.InvalidToken ||
    msg === RefreshTokenError.UserIdMissing ||
    msg === RefreshTokenError.Cognito
  );
};

const isUnstableNetworkErrorMessage = (msg: string) => {
  return msg === RefreshTokenError.Timeout || msg === RefreshTokenError.NetworkRequestFailed;
};

type StoreState = Pick<
  Store,
  | 'refreshToken'
  | 'userProfile'
  | 'permissions'
  | 'tenantName'
  | 'pushTokens'
  | 'termsOfServiceVersionLastAgreedTo'
  | 'tenantMaintenanceStatus'
  | 'isSavingImagesAllowed'
>;

type Store = {
  refreshToken: string | undefined;
  addPushNotificationToken: (token: string) => void;
  userProfile?: ChangeTemporaryPasswordMutation['changeTemporaryPassword']['userProfile'];
  permissions?: {
    groupId: string;
    groupName: string;
    default: boolean;
    permissions: string[];
  }[];
  termsOfServiceVersionLastAgreedTo: string;
  hasRequestedNotificationPermission: boolean;
  UserGroupIdFiltersForNews?: string[];
  tenantName: string;
  isRefreshTokenExpired: boolean;
  tenantMaintenanceStatus: boolean;
  tenantLevelTranslationEnabled: boolean;
  isSavingImagesAllowed?: boolean;
  pushTokens?: Array<string | undefined>;
  _hasHydrated: boolean;
  login: (input: { username: string; password: string }) => Promise<{
    session: string;
  } | void>;
  clearUserStore: () => void;
  logout: ({ queryClientToClearCacheOf, chatStoreToClear }: ILogOut) => Promise<void>;
  refresh: () => Promise<void>;
  setTenantName: (tenantName: string) => void;
  setHasRequestedNotificationPermission: () => void;
  changeTemporaryPassword: (
    input: ChangeTemporaryPasswordMutationVariables['input'],
  ) => Promise<void>;
  headers: () => Promise<Record<string, string | undefined> | undefined>;
  setInitialData: (store?: StoreState) => void;
  isUserLoggedIn: () => boolean;
  getUserPassword: () => string | undefined;
  unsetUserPassword: () => void;
  setTenant: (tenant: TenantConfigurationQuery['tenantConfiguration']) => void;
};

let authToken: string | undefined = undefined;
let userPassword: string | undefined = undefined;

export const useStore = create<Store>()(
  devtools(
    persist(
      (set, get) => ({
        refreshToken: undefined,
        tenantName: 'dev',
        isRefreshTokenExpired: false,
        hasRequestedNotificationPermission: false,
        tenantLevelTranslationEnabled: false,
        tenantMaintenanceStatus: false,
        termsOfServiceVersionLastAgreedTo: '0.0.0',
        isSavingImagesAllowed: undefined,
        _hasHydrated: false,
        login: async ({ username, password }) => {
          const appName = Platform.OS === 'web' ? undefined : appConfig.expo.extra?.appName;

          const res: LoginMutation = await useLoginMutation.fetcher({
            username,
            password,
            tenantName: get().tenantName,
            appName,
          })();
          if (res.login.__typename === 'NewPasswordChallenge') {
            return {
              session: res.login.session,
            };
          }
          if (res.login.__typename === 'UserAuth') {
            authToken = res.login.token;
            userPassword = password;
            set({
              refreshToken: res.login.refreshToken,
              userProfile: res.login.userProfile,
              isRefreshTokenExpired: false,
              permissions: res.login.permissions
                ? res.login.permissions.map(p => ({
                    groupId: p.groupId,
                    groupName: p.groupName,
                    default: p.default,
                    permissions: p.permissions ?? [],
                  }))
                : undefined,
              UserGroupIdFiltersForNews: res.login.permissions
                ? res.login.permissions
                    .filter(p => p.permissions?.includes('news:read'))
                    .map(p => p.groupId) ?? []
                : [],
              pushTokens:
                res.login.pushNotificationDeviceToken?.flatMap(token => token?.deviceToken) ?? [],
              termsOfServiceVersionLastAgreedTo:
                res.login.userProfile?.termsOfServiceVersionLastAgreedTo ?? '0.0.0',
              tenantMaintenanceStatus: res.login.tenantMaintenanceStatus,
            });
          }
        },
        clearUserStore: () => {
          authToken = undefined;
          set({
            refreshToken: undefined,
            isRefreshTokenExpired: undefined,
            userProfile: undefined,
            termsOfServiceVersionLastAgreedTo: '0.0.0',
            UserGroupIdFiltersForNews: undefined,
            isSavingImagesAllowed: undefined,
          });
        },
        logout: async ({ queryClientToClearCacheOf, chatStoreToClear }: ILogOut) => {
          try {
            if (queryClientToClearCacheOf) {
              clearQueryCache(queryClientToClearCacheOf);
            }
            if (chatStoreToClear) {
              chatStoreToClear.clear();
            }
          } catch (e) {
            Log.error(e);
            Log.error('cannot clear stores');
          }
          try {
            await cleanupBiometricStorage();
            const deviceToken = usePushNotificationStatusStore.getState().devicePushToken;
            if (deviceToken) {
              await useDeletePushNotificationDeviceTokenMutation.fetcher({
                deviceToken,
              })();
            }
            usePushNotificationStatusStore.getState().clear();
          } catch (e) {
            Log.warning(e, {
              message:
                'an error occurred while removing push notification token of the device from the database',
            });
          }
          useThemeStore.getState().clear();
          get().clearUserStore();
        },
        refresh: async () => {
          try {
            const result = await refetchToken(get().refreshToken!, get().tenantName);
            authToken = result.refreshToken.token;
            set({
              refreshToken: result.refreshToken.refreshToken,
              userProfile: result.refreshToken.userProfile,
              isRefreshTokenExpired: false,
              permissions: result.refreshToken.permissions
                ? result.refreshToken.permissions.map(p => ({
                    groupId: p.groupId,
                    groupName: p.groupName,
                    default: p.default,
                    permissions: p.permissions ?? [],
                  }))
                : undefined,
              pushTokens:
                result.refreshToken.pushNotificationDeviceToken?.flatMap(
                  token => token?.deviceToken,
                ) ?? [],
              termsOfServiceVersionLastAgreedTo:
                result.refreshToken.userProfile?.termsOfServiceVersionLastAgreedTo ?? '0.0.0',
              tenantMaintenanceStatus: result.refreshToken.tenantMaintenanceStatus,
            });
          } catch (e) {
            Log.error(e, { message: 'cannot refresh' });
          }
        },
        changeTemporaryPassword: async input => {
          const res = await useChangeTemporaryPasswordMutation.fetcher({
            input: input,
            tenantName: get().tenantName,
          })();

          userPassword = input.newPassword;

          return set({
            refreshToken: res.changeTemporaryPassword.refreshToken,
            userProfile: res.changeTemporaryPassword.userProfile,
            permissions: res.changeTemporaryPassword.permissions
              ? res.changeTemporaryPassword.permissions.map(p => ({
                  groupId: p.groupId,
                  groupName: p.groupName,
                  default: p.default,
                  permissions: p.permissions ?? [],
                }))
              : undefined,
            termsOfServiceVersionLastAgreedTo:
              res.changeTemporaryPassword.userProfile?.termsOfServiceVersionLastAgreedTo ?? '0.0.0',
            tenantMaintenanceStatus: res.changeTemporaryPassword.tenantMaintenanceStatus,
          });
        },
        setTenant: tenant => {
          set({
            tenantName: tenant.tenantName,
            tenantLevelTranslationEnabled: tenant.translationEnabled,
          });
        },
        setTenantName: tenantName => {
          if (tenantName !== get().tenantName) {
            set({ tenantName });
          }
        },
        addPushNotificationToken: token => {
          const pushTokens = get().pushTokens ?? [];
          pushTokens.push(token);
          return set({
            pushTokens,
          });
        },
        headers: async () => {
          const refreshToken: string | undefined = get().refreshToken;

          if (!refreshToken) {
            return undefined;
          }

          if (!authToken || !isAuthTokenValid(authToken)) {
            try {
              const result = await refetchToken(refreshToken, get().tenantName);
              if (!result?.refreshToken?.token) {
                throw new Error(RefreshTokenError.UserIdMissing);
              }
              authToken = result.refreshToken.token;
              set({ refreshToken: result.refreshToken.refreshToken, isRefreshTokenExpired: false });
              return {
                Authorization: authToken,
              };
            } catch (e) {
              const error = e as Error;
              if (isTokenInvalidErrorMessage(error.message)) {
                authToken = undefined;
                set({ isRefreshTokenExpired: true, refreshToken: undefined });
                return undefined;
              }
              if (isUnstableNetworkErrorMessage(error.message)) {
                return {
                  Authorization: authToken,
                };
              }
            }
          }

          return {
            Authorization: authToken,
          };
        },
        setInitialData: data => {
          set({
            ...data,
            _hasHydrated: true,
          });
        },
        isUserLoggedIn: () => {
          return (
            (Boolean(get().refreshToken) && Boolean(get().userProfile)) ||
            get().isRefreshTokenExpired
          );
        },
        getUserPassword: () => {
          return userPassword;
        },
        unsetUserPassword: () => {
          userPassword = undefined;
        },
        setHasRequestedNotificationPermission: () => {
          set({ hasRequestedNotificationPermission: true });
        },
      }),
      {
        name: 'luci-auth',
        storage: createJSONStorage(() => AsyncStorage),
        onRehydrateStorage: () => {
          return (state?: Store) => {
            state?.setInitialData();
            if (state?.refreshToken) {
              /* @todo must be awaited */
              return void refetchToken(state.refreshToken, state.tenantName)
                .then(
                  result =>
                    state?.setInitialData({
                      refreshToken: result.refreshToken.refreshToken,
                      userProfile: result.refreshToken.userProfile,
                      permissions: result.refreshToken.permissions
                        ? result.refreshToken.permissions.map(p => ({
                            groupId: p.groupId,
                            groupName: p.groupName,
                            default: p.default,
                            permissions: p.permissions ?? [],
                          }))
                        : undefined,
                      tenantName: state.tenantName ?? 'dev',
                      tenantMaintenanceStatus: result.refreshToken.tenantMaintenanceStatus,
                      pushTokens:
                        result.refreshToken.pushNotificationDeviceToken?.flatMap(
                          token => token?.deviceToken,
                        ) ?? [],
                      termsOfServiceVersionLastAgreedTo:
                        result.refreshToken.userProfile?.termsOfServiceVersionLastAgreedTo ??
                        '0.0.0',
                      isSavingImagesAllowed: state.isSavingImagesAllowed,
                    }),
                )
                .catch(e => {
                  Log.error(e, { message: 'cannot refetch token, rehydrate failed' });
                });
            }
          };
        },
      },
    ),
    { enabled: __DEV__ },
  ),
);
