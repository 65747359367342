import { useBreakpoint } from '../../../hooks';
import { DrawerContentComponentProps } from '@react-navigation/drawer';
import { useEffect } from 'react';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/commonjs/src/types';
import { maxContentAreaWidth } from '../../navigators/ContentNavigator';
import { useWindowDimensions } from 'react-native';

type DrawerPositionType = 'RightDrawer' | 'LeftDrawer';

export const useResponsiveDrawer = (
  props?: Partial<Pick<DrawerContentComponentProps, 'navigation'>> & {
    revealedAt?: ReactNativePaper.Breakpoint;
    drawerPosition?: DrawerPositionType;
  },
) => {
  const { breakpoint } = useBreakpoint();

  const reveal = () => {
    if (props?.navigation && props.drawerPosition) {
      const drawerNavigation = props.navigation.getParent(
        props.drawerPosition,
      ) as DrawerNavigationHelpers;
      drawerNavigation.openDrawer();
    }
  };

  const hide = () => {
    if (props?.navigation && props.drawerPosition) {
      const drawerNavigation = props.navigation.getParent(
        props.drawerPosition,
      ) as DrawerNavigationHelpers;
      drawerNavigation.closeDrawer();
    }
  };

  const { width } = useWindowDimensions();

  const getResponsiveContentWidth = () => {
    if (isLeftDrawerOpen && isRightDrawerOpen) {
      return maxContentAreaWidth;
    } else if (isLeftDrawerOpen) {
      return width - maxContentAreaWidth / 2;
    } else {
      return width;
    }
  };

  const isLeftDrawerOpen = !(breakpoint === 'sm' || breakpoint === 'xs');
  const isRightDrawerOpen = !(breakpoint === 'md' || breakpoint === 'sm' || breakpoint === 'xs');

  const shouldReveal = (revealedAt: ReactNativePaper.Breakpoint) => {
    switch (revealedAt) {
      case 'xl':
        return breakpoint === 'xl';
      case 'lg':
        return breakpoint === 'xl' || breakpoint === 'lg';
      case 'md':
        return breakpoint === 'xl' || breakpoint === 'lg' || breakpoint === 'md';
      case 'sm':
        return (
          breakpoint === 'xl' || breakpoint === 'lg' || breakpoint === 'md' || breakpoint === 'sm'
        );
      case 'xs':
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (props?.revealedAt) {
      if (shouldReveal(props.revealedAt)) {
        reveal();
      } else {
        hide();
      }
    }
  }, [isLeftDrawerOpen, isRightDrawerOpen]);

  return { reveal, hide, isLeftDrawerOpen, isRightDrawerOpen, getResponsiveContentWidth };
};
