import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useBreakpoint } from '../../hooks';
import { useWindowDimensions } from 'react-native';
import { LeftDrawerContent } from '../drawer/LeftDrawerContent';
import { RightDrawerContent } from '../drawer/RightDrawerContent';
import { ContentNavigator, maxContentAreaWidth } from './ContentNavigator';

const RightDrawer = createDrawerNavigator();

const LeftDrawer = createDrawerNavigator();

const LeftDrawerNavigator = () => {
  const { breakpoint } = useBreakpoint();
  const { width } = useWindowDimensions();
  const isDisplayed = breakpoint === 'xl' || breakpoint === 'lg' || breakpoint === 'md';
  const minWidth = width > maxContentAreaWidth ? (width - maxContentAreaWidth) / 2 : 0;

  return (
    <LeftDrawer.Navigator
      // @ts-ignore
      id="LeftDrawer"
      defaultStatus="closed"
      screenOptions={{
        swipeEnabled: false,
        overlayColor: 'transparent',
        drawerType: isDisplayed ? 'permanent' : 'back',
        drawerStyle: {
          minWidth: minWidth,
        },
        drawerPosition: 'left',
      }}
      drawerContent={LeftDrawerContent}
    >
      <LeftDrawer.Screen
        name="Main"
        component={ContentNavigator}
        options={{
          headerShown: false,
        }}
      />
    </LeftDrawer.Navigator>
  );
};

export const RightDrawerNavigator = () => {
  const { breakpoint } = useBreakpoint();
  const { width } = useWindowDimensions();
  const isDisplayed = breakpoint === 'xl' || breakpoint === 'lg';
  const minWidth = width > maxContentAreaWidth ? (width - maxContentAreaWidth) / 2 : 0;

  return (
    <RightDrawer.Navigator
      // @ts-ignore
      id="RightDrawer"
      defaultStatus="closed"
      screenOptions={{
        swipeEnabled: false,
        overlayColor: 'transparent',
        drawerPosition: 'right',
        drawerType: isDisplayed ? 'permanent' : 'back',
        drawerStyle: {
          minWidth: minWidth,
        },
      }}
      drawerContent={RightDrawerContent}
    >
      <RightDrawer.Screen
        name="App"
        component={LeftDrawerNavigator}
        options={{
          headerShown: false,
        }}
      />
    </RightDrawer.Navigator>
  );
};
