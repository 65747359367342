import * as Sentry from '@sentry/react-native';

import { Button, Headline, Subheading, TextInput, withTheme } from 'react-native-paper';
import { Keyboard, Platform, TouchableWithoutFeedback, View, StyleSheet } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Constants from 'expo-constants';
import { OfflineErrorModal } from '../components/Common/Modals/OfflineErrorModal';
import { useSendFeedbackMutation } from '../../graphql/operations';
import { useStore } from '../stores/store';
import { KeyboardAvoidingView } from '../components/HigherOrder';
import { AppTheme } from '../styles/theme';
import { AppBar } from '../components/AppBar/AppBar';
import { toast } from '../utils/toast/toast';
import { FallbackComponentProps } from 'react-native-error-boundary';

type ErrorBoundaryFallbackPropTypes = {
  theme: AppTheme;
  error: FallbackComponentProps;
};

export const ErrorBoundaryFallback = withTheme(
  ({ error, theme }: ErrorBoundaryFallbackPropTypes) => {
    const sendFeedback = useSendFeedbackMutation();
    const userProfile = useStore(s => s.userProfile);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const styles = useMemo(() => createStyles(theme), [theme]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      if (Platform.OS !== 'web') {
        Sentry.captureException(error.error);
      }
    }, []);

    const reload = () => {
      setTimeout(() => {
        error.resetError();
        if (Platform.OS === 'web') {
          location.reload();
        }
      }, 1000);
    };

    const handleSend = useCallback(async () => {
      setIsLoading(true);
      const formattedDescription =
        `${'# App Report' + '\n>>>\n'}${content}\n>>>\n## Error\n\`\`\`json\n${JSON.stringify(
          error,
          null,
          2,
        )}\n\`\`\`` +
        `\n## UserProfile\n\`\`\`json\n${JSON.stringify(
          userProfile || { attributes: 'no user logged in' },
          null,
          2,
        )}\n\`\`\`\n## Platform\n\`\`\`json\n${JSON.stringify(
          Platform,
          null,
          2,
        )}\n\`\`\`\n## Constants\n\`\`\`json\n${JSON.stringify(Constants, null, 2)}\n\`\`\``;

      sendFeedback.mutate(
        {
          input: {
            title: title,
            content: formattedDescription,
          },
        },
        {
          onSuccess: () => {
            setTitle('');
            setContent('');
            setIsLoading(false);
            toast('Vielen Dank für den Fehlerbericht! Die App wird nun neu gestartet.');
            reload();
          },
          onError: () => {
            toast('Feedback senden Fehlgeschlagen! Bitte versuchen Sie es erneut.');
            setIsLoading(false);
            reload();
          },
        },
      );
    }, [error, title, content]);

    return (
      <>
        <AppBar title="Feedback" />
        <OfflineErrorModal>
          {onOnline => (
            <View style={styles.container}>
              <KeyboardAvoidingView style={styles.inputContainer} behavior="padding">
                <TouchableWithoutFeedback
                  onPress={Platform.OS !== 'web' ? Keyboard.dismiss : () => {}}
                >
                  <View style={styles.inputItems}>
                    <Headline style={styles.headline}>Es ist ein Fehler aufgetreten!</Headline>
                    <View style={styles.subheadingContainer}>
                      <Subheading style={styles.subheadingContainer}>
                        Wo ist der Fehler aufgetreten?
                      </Subheading>
                      <TextInput
                        value={title}
                        onChangeText={setTitle}
                        style={styles.textInput}
                        onFocus={() => onOnline(() => {})}
                      />
                    </View>
                    <View style={styles.subheadingContainer}>
                      <Subheading style={styles.subheadingContainer}>
                        Weitere Informationen
                      </Subheading>
                      <TextInput
                        value={content}
                        onChangeText={setContent}
                        multiline
                        numberOfLines={5}
                        style={styles.textInput}
                        onFocus={() => onOnline(() => {})}
                      />
                      <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <Button
                          loading={isLoading}
                          mode="contained"
                          onPress={() => reload()}
                          style={styles.sendButton}
                          textColor={theme.customColors.textWhite}
                        >
                          Abbrechen
                        </Button>
                        <Button
                          loading={isLoading}
                          mode="contained"
                          disabled={
                            isLoading || !title || title.trim() === '' || content.trim() === ''
                          }
                          onPress={() => onOnline(async () => await handleSend())}
                          style={styles.sendButton}
                          textColor={theme.customColors.textWhite}
                        >
                          Fehlerbericht Absenden
                        </Button>
                      </View>
                    </View>
                  </View>
                </TouchableWithoutFeedback>
              </KeyboardAvoidingView>
            </View>
          )}
        </OfflineErrorModal>
      </>
    );
  },
);

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingTop: 50,
      backgroundColor: theme.colors.background,
    },
    inputContainer: {
      flex: 1,
      maxHeight: 350,
    },
    inputItems: {
      flex: 1,
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    headline: {
      alignSelf: 'center',
      textAlign: 'center',
    },
    subheadingContainer: {
      width: '90%',
    },
    subheading: { marginBottom: 14 },
    textInput: { backgroundColor: theme.customColors.textInputBackground },
    sendButton: { alignSelf: 'center', marginTop: 20, borderRadius: 5 },
  });
