import { AudioPlayer } from '../../../components/Common/Media/AudioPlayer';
import React, { memo } from 'react';
import { View, StyleSheet } from 'react-native';
import { UploadType } from '../../../components/Common/FileUpload/types';

type ChatAudioMessageProps = {
  audioPath: string;
  isDownloadConfirmationNeeded?: boolean;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export const ChatAudioMessage = memo(
  ({ audioPath, isDownloadConfirmationNeeded }: ChatAudioMessageProps) => {
    return (
      <View style={styles.container}>
        <AudioPlayer
          uri={audioPath}
          type={UploadType.ChatMessage}
          isDownloadConfirmationNeeded={isDownloadConfirmationNeeded}
        />
      </View>
    );
  },
);
