import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type NotificationStatus = 'granted' | 'denied';

export interface PushNotificationStoreState {
  pushNotificationStatus?: NotificationStatus;
  hasRequestedNotificationPermission: boolean;
  hasRequestedPushNotificationToken: boolean;
  devicePushToken?: string;
  setPushNotificationStatus(pushNotificationStatus: NotificationStatus): void;
  setHasRequestedNotificationPermission: () => void;
  setHasRequestedPushNotificationToken: () => void;
  isPermissionGranted: () => boolean;
  setDevicePushToken: (devicePushToken: string) => void;
  clear: () => void;
}

export const usePushNotificationStatusStore = create<PushNotificationStoreState>()(
  devtools(
    (set, get) => ({
      pushNotificationStatus: undefined,
      hasRequestedNotificationPermission: false,
      hasRequestedPushNotificationToken: false,
      devicePushToken: undefined,
      setHasRequestedNotificationPermission: () => {
        set({ hasRequestedNotificationPermission: true });
      },
      setHasRequestedPushNotificationToken: () => {
        set({ hasRequestedPushNotificationToken: true });
      },
      setPushNotificationStatus: (pushNotificationStatus: NotificationStatus) => {
        set({ pushNotificationStatus });
      },
      setDevicePushToken: (devicePushToken: string) => {
        set({ devicePushToken });
      },
      isPermissionGranted: () => {
        return get().pushNotificationStatus === 'granted';
      },
      clear: () => {
        set({
          pushNotificationStatus: undefined,
          hasRequestedNotificationPermission: false,
          hasRequestedPushNotificationToken: false,
        });
      },
    }),
    { enabled: __DEV__ },
  ),
);
