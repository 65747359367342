import {
  ChatRole,
  ChatRoomsQuery,
  NewsAttachmentType,
  useHideChatRoomMutation,
  useInfiniteChatRoomsQuery,
  usePinChatRoomMutation,
  useUnpinChatRoomMutation,
} from '../../../../graphql/operations';
import React, { memo, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { TouchableRipple, useTheme, Text } from 'react-native-paper';

import { AppNavigatorParamList } from '../../../router/AppNavigator';
import { AvatarDisplay } from '../../../components/Common/AvatarDisplay/AvatarDisplay';
import Highlighter from '@luciapp/react-native-highlight-words';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { OfflineErrorModal } from '../../../components/Common/Modals/OfflineErrorModal';
import { StackNavigationProp } from '@react-navigation/stack';
import moment from 'moment';
import { themeProp } from '../../../types/main';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useNavigation } from '@react-navigation/native';
import { useQueryClient } from '@tanstack/react-query';
import { useStore } from '../../../stores/store';
import { getAvatarForChatRoom } from '../utils';
import { useBreakpoint } from '../../../hooks';
import { UploadType } from '../../../components/Common/FileUpload/types';
import { ChatRoomUnreadMessageCount } from './ChatRoomUnreadMessageCount';
import { ChatRoomModeratorIcon } from './ChatRoomModeratorIcon';
import { useChatMessageTyping } from '../hooks/useChatMessageTyping';
import { getChatMessageBeingTypedText } from '../utils/getChatMessageBeingTypedText';

export type ChatListItemProps = {
  item: ChatRoomsQuery['chatRoomsListForTheCurrentUser']['chatRooms'][number];
  searchValue?: string;
  onHideChatRoomError: (e: unknown) => void;
};

export const ChatListItem = memo(
  ({ searchValue, item, onHideChatRoomError }: ChatListItemProps) => {
    const navigation = useNavigation<StackNavigationProp<AppNavigatorParamList>>();
    const userProfile = useStore(s => s.userProfile);
    const theme = useTheme() as themeProp;
    const queryClient = useQueryClient();
    const pinChat = usePinChatRoomMutation();
    const { typingEvents } = useChatMessageTyping({ chatRoomId: item.id, chatRoomType: item.type });
    const unpinChat = useUnpinChatRoomMutation();
    const hideChat = useHideChatRoomMutation();
    const { breakpoint } = useBreakpoint();
    const isLeftDrawerOpen = breakpoint === 'xl' || breakpoint === 'lg' || breakpoint === 'md';
    const styles = useMemo(
      () => createStylesheet(theme, isLeftDrawerOpen),
      [theme, isLeftDrawerOpen],
    );
    const { showActionSheetWithOptions } = useActionSheet();
    const isGroupChat = !!item.name;
    const isUserModeratorOfGroupChat =
      item.members?.find(member => member.userId === userProfile?.userId)?.role ===
      ChatRole.Moderator;
    const typingEventsOfChatRoom = typingEvents
      .filter(event => event.value.chatRoomId === item.id)
      .map(event => event.value);

    const typingText = getChatMessageBeingTypedText({
      typingEvents: typingEventsOfChatRoom,
      isGroupChat,
    });

    const renderSubText = () => {
      if (typingText) {
        return (
          <Text variant="bodySmall" numberOfLines={1}>
            {typingText ?? ' '}
          </Text>
        );
      }
      return (
        <Text variant="bodySmall" numberOfLines={1}>
          {item.lastMessage &&
            formatTinyMessageText({
              content: item.lastMessage.content,
              authorName: `${item.lastMessage.author?.firstname ?? 'Ehemaliger'} ${
                item.lastMessage.author?.lastname ?? 'Benutzer'
              }`,
            })}
        </Text>
      );
    };

    const chatRoomName = useMemo(() => {
      if (item.name) {
        return item.name;
      }
      const partner = item.members?.find(member => member.userId !== userProfile?.userId);
      if (partner) {
        return `${partner?.firstname} ${partner?.lastname}`;
      }

      return 'Ehemaliger Benutzer';
    }, [item, userProfile?.userId]);

    const chatRoomImage = useMemo(() => {
      return getAvatarForChatRoom({
        isGroupChat,
        chatPartners: item.members,
        userId: userProfile?.userId,
        chatImageUri: item.groupImagePath,
        name: item.name,
      });
    }, [item, userProfile]);

    const handlePress = () => {
      if (item?.id && item.members) {
        navigation.navigate('App', {
          screen: 'Main',
          params: {
            screen: 'ChatRoom',
            params: {
              id: item.id,
              name: chatRoomName,
              type: item.type,
              imageUri: chatRoomImage.uri,
              members: item.members,
              role: isUserModeratorOfGroupChat ? ChatRole.Moderator : ChatRole.Member,
            },
          },
        });
      }
    };

    const handleLongPress = () => {
      const options: Array<string> = [];
      if (item.statusOfCurrentUser?.pinned) {
        options.push('Loslösen');
      } else {
        options.push('Anpinnen');
      }
      options.push('Ausblenden');
      options.push('Abbrechen');

      options.push();
      const cancelButtonIndex = options.length - 1;

      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
        },
        async (buttonIndex: any) => {
          switch (buttonIndex) {
            case 0:
              return item.statusOfCurrentUser?.pinned
                ? unpinChat.mutate(
                    {
                      chatRoomId: item.id,
                    },
                    {
                      onSuccess: () => {
                        /* @todo must be awaited */
                        void queryClient.invalidateQueries<ChatRoomsQuery>(
                          useInfiniteChatRoomsQuery.getKey({
                            pageInput: { pageSize: 20 },
                          }),
                          {
                            refetchPage: page =>
                              page.chatRoomsListForTheCurrentUser.chatRooms.some(
                                chatRoom => chatRoom.id === item.id,
                              ),
                          },
                        );
                      },
                    },
                  )
                : pinChat.mutate(
                    {
                      chatRoomId: item.id,
                    },
                    {
                      onSuccess: () => {
                        /* @todo must be awaited */
                        void queryClient.invalidateQueries<ChatRoomsQuery>(
                          useInfiniteChatRoomsQuery.getKey({
                            pageInput: { pageSize: 20 },
                          }),
                          {
                            refetchPage: page =>
                              page.chatRoomsListForTheCurrentUser.chatRooms.some(
                                chatRoom => chatRoom.id === item.id,
                              ),
                          },
                        );
                      },
                    },
                  );
            case 1:
              if (item?.id) {
                return hideChat.mutate(
                  {
                    chatRoomId: item.id,
                  },
                  {
                    onSuccess: () => {
                      /* @todo must be awaited */
                      void queryClient.invalidateQueries<ChatRoomsQuery>(
                        useInfiniteChatRoomsQuery.getKey({
                          pageInput: { pageSize: 20 },
                        }),
                        {
                          refetchPage: page =>
                            page.chatRoomsListForTheCurrentUser.chatRooms.some(
                              chatRoom => chatRoom.id === item.id,
                            ),
                        },
                      );
                    },
                    onError: onHideChatRoomError,
                  },
                );
              }
              return;
          }
        },
      );
    };

    return (
      <OfflineErrorModal>
        {onOnline => (
          <>
            <TouchableRipple
              rippleColor={theme.customColors.lightRipple}
              onPress={handlePress}
              onLongPress={() => onOnline(handleLongPress)}
              style={styles.row}
            >
              <View style={styles.rowContent}>
                <View style={styles.avatarContainer}>
                  <AvatarDisplay
                    avatar={{ initials: chatRoomImage.initials, otcPath: chatRoomImage.uri }}
                    size={60}
                    type={isGroupChat ? UploadType.GroupChatImage : UploadType.ProfilePicture}
                  />
                  {item.unreadMessageCount && item.unreadMessageCount !== 0 ? (
                    <ChatRoomUnreadMessageCount unreadMessageCount={item.unreadMessageCount} />
                  ) : (
                    <></>
                  )}
                  {isUserModeratorOfGroupChat ? <ChatRoomModeratorIcon /> : <></>}
                </View>
                <View style={styles.mainContent}>
                  <View style={styles.itemRow}>
                    <Highlighter
                      highlightStyle={{ fontWeight: '700' }}
                      searchWords={[searchValue ?? '']}
                      textToHighlight={chatRoomName}
                      style={styles.chatName}
                    />
                    {
                      <Text style={styles.msgTxt}>
                        {formatTinyDate(item.lastMessage?.createdAt ?? item.createdAt)}
                      </Text>
                    }
                  </View>
                  <View style={styles.itemRow}>
                    <View style={styles.msgContainer}>{renderSubText()}</View>
                    {Boolean(item.statusOfCurrentUser?.pinned) && (
                      <Icon
                        name="pin"
                        size={20}
                        key="3"
                        color={theme.customColors.gray60}
                        style={{ padding: 5, transform: [{ rotate: '45deg' }] }}
                      />
                    )}
                  </View>
                </View>
              </View>
            </TouchableRipple>
          </>
        )}
      </OfflineErrorModal>
    );
  },
);

export const formatTinyMessageText = ({
  content,
  authorName,
  type,
}: {
  content: string;
  authorName: string;
  type?: NewsAttachmentType;
}) => {
  if (type) {
    switch (type) {
      case NewsAttachmentType.Image:
        return `${authorName}: Bild 🖼`;
      case NewsAttachmentType.Video:
        return `${authorName}: Video 📽`;
      case NewsAttachmentType.Audio:
        return `${authorName}: Sprachnachricht 🎤`;
      case NewsAttachmentType.Document:
        return `${authorName}: Dokument 📄`;
    }
  }

  return `${authorName}: ${content}`;
};

const formatTinyDate = (timestamp: Date) => {
  const a = moment(timestamp);
  const dayDiff = moment().diff(a, 'days');
  if (a.format('l') === moment().format('l')) {
    return a.format('LT');
  } else if (dayDiff <= 7) {
    return a.format('dd[.]');
  } else {
    return a.format('l');
  }
};

const createStylesheet = (theme: themeProp, isLeftDrawerOpen: boolean) =>
  StyleSheet.create({
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      borderColor: theme.customColors.borders,
      backgroundColor: theme.colors.background,
      borderBottomWidth: isLeftDrawerOpen ? 0 : 1,
      paddingVertical: 10,
      paddingRight: 10,
      width: '100%',
    },
    rowContent: {
      flex: 1,
      width: '100%',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    pinned: {
      borderColor: theme.customColors.gray50,
      borderBottomWidth: 1.2,
    },
    avatarContainer: { marginHorizontal: 12 },
    nameTxt: {
      marginLeft: 15,
      fontWeight: '600',
      color: '#222',
      fontSize: 18,
    },
    mainContent: {
      flex: 1,
    },
    itemRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    chatName: {
      color: theme.colors.onSurface,
      fontSize: 17,
      fontWeight: '500',
    },
    msgContainer: {
      flexDirection: 'row',
      textAlign: 'left',
      alignSelf: 'flex-start',
      maxWidth: '85%',
    },
    msgTxt: {
      fontWeight: '400',
      color: theme.customColors.textGray,
      fontSize: 14,
      marginTop: 3,
    },
    action: {
      // flex: 1
    },
    icon: {
      padding: 8,
      color: '#f5f5f5',
      marginTop: 25,
    },
  });
