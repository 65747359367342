import { Manager, Socket } from 'socket.io-client';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Log } from '../utils/Log';

const manager = new Manager(process.env.EXPO_PUBLIC_WEBSOCKET_URL, {
  // path: '/socket.io',
  transports: ['websocket'],
  reconnection: true,
  autoConnect: false,
  withCredentials: true,
  secure: true,
});

type GetHeaders = () => Promise<Record<string, string | undefined> | undefined>;

export interface SocketStore {
  connect: (getHeaders: GetHeaders, tenantName: string) => void;
  clear: () => void;
  newsSocket?: Socket;
  chatSocket?: Socket;
  isConnected: () => boolean;
}

export const useSocketStore = create<SocketStore>()(
  devtools(
    (set, get) => ({
      clear: () => {
        Log.info('clear, disconnect off close');
        get().newsSocket?.off('connect');
        get().newsSocket?.off('connect_error');
        get().newsSocket?.off('disconnect');
        get().newsSocket?.offAny();
        get().newsSocket?.offAnyOutgoing();
        get().newsSocket?.disconnect().close();
        get().chatSocket?.off('connect');
        get().chatSocket?.off('connect_error');
        get().chatSocket?.off('disconnect');
        get().chatSocket?.offAny();
        get().chatSocket?.offAnyOutgoing();
        get().chatSocket?.disconnect().close();
        manager?.engine?.off();
        manager?.engine?.close();
        set({ newsSocket: undefined, chatSocket: undefined });
      },
      connect: (getHeaders: GetHeaders, tenantName: string) => {
        Log.info('connect to socket');
        if (get().newsSocket) {
          if (!get().newsSocket?.connected) {
            Log.info('get().newsSocket?.connect();');
            get().newsSocket?.connect();
          }
          if (!get().chatSocket?.connected) {
            Log.info('get().chatSocket?.connect();');
            get().chatSocket?.connect();
          }
          return;
        }
        Log.info('create new sockets');
        const newsSocket = manager.socket(`/news_${tenantName}`, {
          auth: async cb => {
            const headers = await getHeaders();
            cb({ token: headers?.Authorization ?? '' });
          },
        });
        const chatSocket = manager.socket(`/chat_${tenantName}`, {
          auth: async cb => {
            const headers = await getHeaders();
            cb({ token: headers?.Authorization ?? '' });
          },
        });

        newsSocket.on('connect', () => {
          Log.info('news socket io is successfully connected');
        });
        newsSocket.on('connect_error', error => {
          Log.info(`an error occurred while connecting to news socket io: ${error.message}`);
        });
        newsSocket.on('disconnect', reason => {
          Log.info(`news socket io is successfully disconnected: ${reason}`);
        });
        chatSocket.on('connect', () => {
          Log.info('chat socket io is successfully connected');
        });
        chatSocket.on('connect_error', error => {
          Log.info(`an error occurred while connecting to chat socket io: ${error.message}`);
        });
        chatSocket.on('disconnect', reason => {
          Log.info(`chat socket io is successfully disconnected: ${reason}`);
        });
        if (!chatSocket.connected) {
          Log.info('!connected: connect!');
          chatSocket.connect();
        }
        if (!newsSocket.connected) {
          Log.info('!connected: connect!');
          newsSocket.connect();
        }
        set({ newsSocket, chatSocket });
      },
      isConnected: () => {
        const { newsSocket, chatSocket } = get();
        return Boolean(newsSocket?.connected && chatSocket?.connected);
      },
    }),
    { enabled: __DEV__ },
  ),
);
