import { ActivityIndicator, FlatList, Platform, RefreshControl, View } from 'react-native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Appbar, Snackbar, Text } from 'react-native-paper';
import { NewsComponent } from '../../components/News/News.component';
import { NoDataRefresh } from '../../components/Common/Loading/NoDataRefresh';
import {
  NewsToReadByUserGroupQuery,
  useGetUserPreferenceQuery,
  useInfiniteNewsToReadByUserGroupQuery,
  usePinnedNewsListQuery,
} from '../../../graphql/operations';
import { useIsFocused } from '@react-navigation/native';
import { useNetInfo } from '@react-native-community/netinfo';
import { useUserGroupsOfUser } from './hooks/useUserGroupsOfUser';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import * as Notifications from 'expo-notifications';
import { useStore } from '../../stores/store';
import { TermsOfServicePopUp } from '../../components/Common/Modals/TermsOfServicePopUp';
import { NewsFeed, NewsTimelineTestId } from '../../../test/testIds';
import { TranslationSetupPopUp } from '../../components/Common/Modals/TranslationSetupPopUp';
import {
  determineContactDetailsUnVerified,
  UnverifiedContactDetailsBar,
} from './unverifiedContactDetails';
import { useAppTheme } from '../../styles/theme';
import { useRefreshControlWithTimeout } from '../../hooks/list/useRefreshControlWithTimeout';
import {
  useRequestBiometricsPopup,
  useRequestPushNotificationPermission,
  useUnavailableFeatureModalStore,
  useUploadPushNotificationToken,
  useUserMustAgreeToTermsOfService,
} from '../../hooks';
import { PinnedNewsItem } from './pinnedNewsItem';
import { UnavailableFeatureModal } from '../../components/Common/Modals/UnavailableFeatureModal';
import { useAppBar } from './hooks/useAppBar';
import { SnackbarContent } from '../../components/SnackbarContent/SnackbarContent';
import { useAppStatus } from '../../hooks/common/appStatus';
import { AppBar } from '../../components/AppBar/AppBar';
import { NewsFilterAppBarAction } from '../../components/News/NewsFilterAppBarAction';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import { NewsHomeScreenProps } from './types';
import { FullScreenModalPicker } from '../../components/Picker/FullScreenModalPicker';
import { NewsCreationNotAvailableModal } from './newsCreation/components';
import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import { WithLoadingIndicator } from '../../components/WithLoadingIndicator/WithLoadingIndicator';
import { useUnreadNewsStore } from '../../stores/useUnreadNewsStore';
import { NewsSeparator } from '../../components/News/NewsSeparator';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { Log } from '../../utils/Log';

const SNACKBAR_MARGIN = 64;

export const NewsTimelineScreen = ({ navigation }: NewsHomeScreenProps) => {
  const theme = useAppTheme();
  const netInfo = useNetInfo();
  const [isNotificationsSnackbarShown, setNotificationsSnackbarShown] = useState(false);
  const isFocused = useIsFocused();
  const { appMovedToView } = useAppStatus();
  const newsCountStore = useUnreadNewsStore();
  const {
    onEmptySearchPress,
    onNewsTagPress: onTagPress,
    searchInputValue,
    debouncedSearchValue,
    showSearch,
    setShowSearch,
    setSearchInputValue,
  } = useAppBar();
  const { userProfile, tenantLevelTranslationEnabled, isNewsEditor } = useStore(s => ({
    userProfile: s.userProfile,
    tenantLevelTranslationEnabled: s.tenantLevelTranslationEnabled,
    isNewsEditor:
      (s.permissions
        ?.map(permissionByUserGroup => permissionByUserGroup.permissions.includes('news:create'))
        .filter(permission => permission === true)?.length ?? 0) > 0,
  }));
  const queryClient = useQueryClient();

  const userUnverifiedContactDetails = useMemo(
    () => determineContactDetailsUnVerified(userProfile),
    [userProfile],
  );

  const userPreference = useGetUserPreferenceQuery(
    {
      userId: userProfile?.userId ?? '',
    },
    { enabled: !!userProfile?.userId },
  );

  const unavailableFeatureModal = useUnavailableFeatureModalStore();

  const [newsCreationNotAvailable, setNewsCreationNotAvailable] = useState(false);

  const checkNotificationPermissions = async () => {
    if (!isNotificationsSnackbarShown) {
      return;
    }
    const { status } = await Notifications.getPermissionsAsync();
    setNotificationsSnackbarShown(status !== 'granted');
  };

  const { savedUserGroupIdsForNewsFilter } = useStore(s => ({
    savedUserGroupIdsForNewsFilter: s.UserGroupIdFiltersForNews ?? [],
  }));

  const {
    isInitialLoading: groupsAreLoading,
    isError: groupsHaveFailed,
    isRefetching: groupsAreRefetching,
    isSelectionsSameWithOptions,
    options,
    selections,
    selectionsBuffer,
    isSelecting,
    refetchGroups,
    toggleSelecting,
    syncSelectionsWithBuffer,
    onSelectionsBufferChange,
    resetBufferToSelections,
  } = useUserGroupsOfUser({
    shouldStoreBeSynced: true,
    initialSelections: savedUserGroupIdsForNewsFilter,
  });

  const newsList = useInfiniteNewsToReadByUserGroupQuery(
    'pageInput',
    {
      pageInput: { pageSize: 8 },
      searchText: debouncedSearchValue,
      userGroupIdsOfNews: selections,
    },
    {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: false,
      getNextPageParam: (lastPage, allPages) => {
        const alreadyFetched = allPages.reduce(
          (count, page) => count + page.newsListByUserGroup.news.length,
          0,
        );
        if (alreadyFetched === lastPage.newsListByUserGroup.totalCount) {
          return;
        }
        return {
          pageInput: {
            pageSize: 8,
            token: lastPage.newsListByUserGroup?.token,
          },
        };
      },
      retry: false,
    },
  );

  const pinnedNewsQuery = usePinnedNewsListQuery(
    { userGroupIds: selections },
    { enabled: netInfo.isConnected ?? false },
  );

  const pinnedNews = pinnedNewsQuery.data?.pinnedNewsList;

  const userGroupsUserIsPartOf = useMemo(
    () =>
      options.map(userGroup => {
        return {
          id: userGroup.id,
          label: userGroup.label,
        };
      }),
    [options],
  );

  const refetchAll = async () => {
    await userPreference.refetch();
    await refetchGroups();
    await newsList.refetch({
      refetchPage: (_lastPage, index) => {
        return index === 0;
      },
    });
    await pinnedNewsQuery.refetch();
  };

  const userMustAgreeToTermsOfService = useUserMustAgreeToTermsOfService();
  const biometricsPopup = useRequestBiometricsPopup(userMustAgreeToTermsOfService);

  useEffect(() => {
    if (isFocused && isSelecting === false) {
      /* @todo must be awaited */
      void refetchAll();
      /* @todo must be awaited */
      void checkNotificationPermissions();
    }
  }, [isFocused]);

  useEffect(() => {
    if (isFocused && newsCountStore.count > 0) {
      newsCountStore.reset();
    }
  }, [newsCountStore.count, isFocused]);

  useRequestPushNotificationPermission(userMustAgreeToTermsOfService);
  useUploadPushNotificationToken();

  const onAppMovedToView = useCallback(async () => {
    try {
      await resetNews();
      await userPreference.refetch();
      await refetchGroups();
      await pinnedNewsQuery.refetch();
    } catch (e) {
      Log.error(e, { message: 'cannot update news timeline on app moved to view' });
    }
  }, []);

  const resetNews = async () => {
    try {
      const data = queryClient.getQueryCache().findAll(['newsToReadByUserGroup.infinite'], {
        exact: false,
        type: 'all',
      });

      await Promise.all(
        data.map(item => {
          const pageSize =
            (item.state.data as InfiniteData<NewsToReadByUserGroupQuery>)?.pages?.length ?? 0;
          const hasDataMoreThanTwoPages = Boolean(pageSize && pageSize > 2);
          if (hasDataMoreThanTwoPages) {
            return queryClient.resetQueries({ queryKey: item.queryKey });
          }
          return Promise.resolve();
        }),
      );
      await newsList.refetch({ refetchPage: (_lastPage, index) => index === 0 });
    } catch (e) {
      Log.error(e, { message: 'Cannot reset news' });
    }
  };

  useEffect(() => {
    if (appMovedToView) {
      void onAppMovedToView();
      void checkNotificationPermissions();
    }
  }, [appMovedToView]);

  const onNotificationSnackbarAction = () => {
    navigation.navigate('Settings');
  };

  const onContactDetailsSnackbarAction = () => {
    navigation.navigate('ProfileEdit');
  };

  const { isRefreshing, onRefresh } = useRefreshControlWithTimeout({
    asyncFunctionToAwaitFor: () => refetchAll(),
    errorMessageToLog: `failed to refetch data for the news timeline`,
  });

  const [isTranslationSetupPopUpShown, setTranslationSetupPopUpShown] = useState(false);

  const isListDataLoading =
    newsList.isInitialLoading || userPreference.isInitialLoading || groupsAreLoading;

  const isError =
    !isListDataLoading && (newsList.isError || userPreference.isError || groupsHaveFailed);

  const isRefetching =
    !isListDataLoading &&
    (newsList.isRefetching || userPreference.isRefetching || groupsAreRefetching);

  const areButtonsDisabled = isError || isListDataLoading;

  return (
    <View
      style={{ height: '100%', backgroundColor: theme.colors.background }}
      testID={NewsTimelineTestId.container}
    >
      <TermsOfServicePopUp userMustAgreeToTermsOfService={userMustAgreeToTermsOfService} />
      <ConfirmDialog
        onCancel={biometricsPopup.onCancel}
        onConfirm={biometricsPopup.onConfirm}
        title="Biometrischer Login"
        content="Wollen Sie Ihre gespeicherten biometrischen Daten zum Login für die App LUCI verwenden?"
        confirmButtonLabel="Aktivieren"
        isOpen={biometricsPopup.isVisible}
      />
      <TranslationSetupPopUp
        visible={isTranslationSetupPopUpShown}
        setVisible={setTranslationSetupPopUpShown}
      />
      <FullScreenModalPicker<string>
        title="Nach Organisationsbereich filtern"
        isVisible={isSelecting}
        onClose={resetBufferToSelections}
        isConfirmSelectionDisabled={selectionsBuffer.length === 0}
        isOptionsLoading={groupsAreLoading}
        onPressConfirmSelection={syncSelectionsWithBuffer}
        onPressRefreshOptions={refetchGroups}
        options={options}
        selectionsBuffer={selectionsBuffer}
        onSelectionBufferChange={onSelectionsBufferChange}
        renderOptionLabel={option => (
          <Text
            style={{
              color: theme.customColors.text,
              padding: 5,
            }}
            numberOfLines={1}
          >
            {option.label}
          </Text>
        )}
      />
      <UnavailableFeatureModal
        visible={unavailableFeatureModal.isVisible}
        displayButton={unavailableFeatureModal.isButtonDisplayed}
        includeAppStoreLinks={unavailableFeatureModal.isAppStoreLinksIncluded}
        setVisible={unavailableFeatureModal.setVisible}
        text={{ description: unavailableFeatureModal.description }}
        icon={unavailableFeatureModal.icon}
      />
      <NewsCreationNotAvailableModal
        visible={newsCreationNotAvailable}
        setVisible={setNewsCreationNotAvailable}
      />
      <AppBar
        title="News"
        showSearch={showSearch}
        isSearchDisabled={areButtonsDisabled}
        onTitlePress={resetNews}
        onPressSearch={() => setShowSearch(true)}
        renderActionsLeftOfTitle={() => (
          <NewsFilterAppBarAction
            isSomeFilterApplied={!isSelectionsSameWithOptions}
            isDisabled={areButtonsDisabled}
            onPress={toggleSelecting}
          />
        )}
        renderSearchBar={() => (
          <SearchBar
            onChangeSearchValue={setSearchInputValue}
            onPressCloseSearch={onEmptySearchPress}
            searchInputValue={searchInputValue}
          />
        )}
        renderActionsRightOfSearch={
          isNewsEditor
            ? () => (
                <Appbar.Action
                  testID="appbar-add-news-button"
                  icon="plus"
                  size={26}
                  color={theme.customColors.gray50}
                  onPress={() => {
                    if (Platform.OS === 'web') {
                      return setNewsCreationNotAvailable(true);
                    }
                    return navigation.navigate('NewNews');
                  }}
                  disabled={areButtonsDisabled}
                />
              )
            : undefined
        }
      />
      {isError ? (
        <NoDataRefresh
          text="Es konnten leider keine News gefunden werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut."
          onPress={refetchAll}
          isLoading={isRefetching}
        />
      ) : (
        <WithLoadingIndicator
          on={!isListDataLoading}
          renderWhileLoading={() => (
            /* @todo replace with shimmers  */
            <View
              style={[
                {
                  flex: 1,
                  justifyContent: 'center',
                },
                {
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 10,
                },
              ]}
            >
              <ActivityIndicator size="large" color={theme.customColors.primary} />
            </View>
          )}
          render={() => (
            <FlatList
              ItemSeparatorComponent={() => <NewsSeparator />}
              testID={NewsFeed.newslist}
              refreshControl={
                <RefreshControl
                  refreshing={isRefreshing}
                  onRefresh={() => void onRefresh()}
                  tintColor={theme.customColors.refreshControlSpinner}
                />
              }
              data={newsList.data?.pages.flatMap(news => news.newsListByUserGroup.news)}
              renderItem={({ item }) => (
                <NewsComponent
                  news={item}
                  userGroupsUserIsPartOf={userGroupsUserIsPartOf}
                  translationEnabled={
                    (userPreference.data?.getUserPreference.translationEnabled &&
                      tenantLevelTranslationEnabled) ??
                    false
                  }
                  translationLanguage={userPreference.data?.getUserPreference.translationLanguage}
                  setTranslationSetupPopUpShown={setTranslationSetupPopUpShown}
                  groupIdsInNewsTimelineQuery={selections}
                  searchTextInNewsTimeLineQuery={debouncedSearchValue}
                  onTagPress={onTagPress}
                />
              )}
              stickyHeaderHiddenOnScroll
              stickyHeaderIndices={[0]}
              ListHeaderComponent={() => (
                <>
                  {pinnedNews ? (
                    <>
                      {pinnedNews.map(item => (
                        <PinnedNewsItem
                          key={item.id}
                          news={item}
                          userGroupsUserIsPartOf={userGroupsUserIsPartOf}
                          translationEnabled={false}
                          translationLanguage={undefined}
                        />
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              style={{
                backgroundColor: theme.colors.background,
              }}
              keyExtractor={news => news.id}
              windowSize={8}
              ListEmptyComponent={
                <>
                  {!!newsList.data && (
                    <NoDataRefresh
                      text="Es wurden keine News gefunden. Versuchen Sie, Filter zu entfernen, falls welche angewendet werden"
                      onPress={() => newsList.refetch()}
                      isLoading={newsList.isRefetching}
                    />
                  )}
                </>
              }
              onEndReachedThreshold={2}
              onEndReached={() => void newsList.fetchNextPage()}
              extraData={searchInputValue}
              ListFooterComponent={
                <View style={{ display: 'flex', marginBottom: 5 }}>
                  {newsList.isFetchingNextPage && newsList.hasNextPage && (
                    <ActivityIndicator animating color={theme.customColors.primary} size="large" />
                  )}
                </View>
              }
            />
          )}
        />
      )}
      <UnverifiedContactDetailsBar
        isVisible={userUnverifiedContactDetails}
        onPress={onContactDetailsSnackbarAction}
        snackStyle={{ marginBottom: isNotificationsSnackbarShown ? SNACKBAR_MARGIN : 5 }}
      />
      <Snackbar
        visible={isNotificationsSnackbarShown && Platform.OS !== 'web'}
        onDismiss={() => {}}
        onTouchEnd={onNotificationSnackbarAction}
        style={{ backgroundColor: theme.customColors.snackbarBackground }}
        action={{
          label: '',
          icon: () => (
            <Icon
              name={'cog'}
              size={18}
              color={theme.colors.primary}
              onPress={onNotificationSnackbarAction}
            />
          ),
          onPress: onNotificationSnackbarAction,
        }}
      >
        <SnackbarContent icon="bell-off-outline" text="Benachrichtigungen deaktiviert." />
      </Snackbar>
    </View>
  );
};
