import React, { useEffect, useState } from 'react';

import { AppNavigatorParamList } from '../../router/AppNavigator';
import ConfirmScreen from './ConfirmScreen';
import Markdown from 'react-native-markdown-display';
import { MdStyles } from '../../screens/Informational/MarkdownStyles';
import { StackScreenProps } from '@react-navigation/stack';
import { Alert, View } from 'react-native';
import { useLegal } from '../../hooks/common/legal';
import { useUpdateTermsOfServiceVersionLastAgreedToMutation } from '../../../graphql/operations';
import { termsOfServiceVersion } from '../../../app.config';
import { useStore } from '../../stores/store';
import { Log } from '../../utils/Log';
import { useAppTheme } from '../../styles/theme';
import { Modal, Portal } from 'react-native-paper';
import { maxContentAreaWidth } from '../../router/navigators/ContentNavigator';
import { useQueryClient } from '@tanstack/react-query';
import { LoginLegal } from '../../../test/testIds';

type LoginLegalScreenProps = StackScreenProps<AppNavigatorParamList, 'LegalFlow'>;

export const LoginLegalScreen = (props: LoginLegalScreenProps) => {
  const queryClient = useQueryClient();
  const { privacy, consent, fetchConsent, fetchPrivacy, fetchingError } = useLegal();
  const theme = useAppTheme();
  const updateTermsOfServiceVersionLastAgreedTo =
    useUpdateTermsOfServiceVersionLastAgreedToMutation();
  const [authCase, setAuthCase] = useState('privacy');
  const { userProfile, logout } = useStore(s => ({ userProfile: s.userProfile, logout: s.logout }));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    /* @todo must be awaited */
    void fetchConsent();
    void fetchPrivacy();
  }, []);

  const displayAlert = (logMessage: string) => {
    Log.warning(JSON.stringify(logMessage, null, 4));
    Alert.alert(
      'Ein Fehler ist aufgetreten',
      'Bitte versuchen Sie es noch einmal.',
      [
        {
          text: 'Zurück',
          onPress: () =>
            void logout({ queryClientToClearCacheOf: queryClient, chatStoreToClear: undefined }),
          style: 'cancel',
        },
      ],
      {
        cancelable: false,
      },
    );
  };

  return (
    <Portal>
      <Modal
        visible={!!consent || !!privacy}
        dismissable={false}
        contentContainerStyle={{
          backgroundColor: theme.colors.background,
          padding: 20,
          height: '100%',
          width: '100%',
          maxWidth: maxContentAreaWidth,
          alignSelf: 'center',
        }}
      >
        {authCase === 'privacy' && privacy ? (
          <ConfirmScreen
            buttonText="Gelesen"
            submitButtonTestId={LoginLegal.privacySubmitButton}
            enableDelay={4000}
            onChange={() => {
              setAuthCase('consent');
            }}
          >
            <Markdown
              style={{
                body: {
                  marginVertical: 20,
                },
                ...MdStyles(theme),
              }}
              mergeStyle
            >
              {privacy}
            </Markdown>
          </ConfirmScreen>
        ) : (
          fetchingError && (
            <View onLayout={() => displayAlert('Privacy document could not be fetched.')} />
          )
        )}
        {authCase === 'consent' && consent ? (
          <ConfirmScreen
            buttonText="Zustimmen"
            enableDelay={750}
            submitButtonTestId={LoginLegal.consentSubmitButton}
            isLoading={isLoading}
            onChange={() => {
              setIsLoading(true);
              updateTermsOfServiceVersionLastAgreedTo.mutate(
                {
                  input: {
                    userId: userProfile!.userId,
                    termsOfServiceVersion: termsOfServiceVersion,
                  },
                },
                {
                  onSuccess: () => {
                    useStore.setState({
                      termsOfServiceVersionLastAgreedTo: termsOfServiceVersion,
                    });
                    props.navigation.navigate('Tabs', {
                      screen: 'News',
                      params: {
                        screen: 'NewsOverview',
                      },
                    });
                    props.navigation.reset({
                      index: 0,
                      routes: [{ name: 'Home' }],
                    });
                  },
                  onError: e => {
                    const errorMessage =
                      'An error occurred when saving the terms of service last agreed to version';

                    Log.error(errorMessage, { message: e as any });

                    return displayAlert(errorMessage);
                  },
                },
              );
            }}
          >
            <Markdown
              style={{
                body: {
                  marginVertical: 20,
                },
                ...MdStyles(theme),
              }}
              mergeStyle
            >
              {consent}
            </Markdown>
          </ConfirmScreen>
        ) : (
          fetchingError && (
            <View onLayout={() => displayAlert('Consent document could not be fetched.')} />
          )
        )}
      </Modal>
    </Portal>
  );
};
