import { ExpoConfig } from 'expo/config';

import base from './config/base.config';
import luci from './config/luci.config';
import arcus from './config/tenantSpecific/arcus.config';

function matchConfigPathToConfigFile() {
  const appName = process.env.EXPO_PUBLIC_APP_NAME;
  switch (appName) {
    case 'luci':
      return luci;
    case 'arcus':
      return arcus;
    default:
      return luci;
  }
}

const custom = matchConfigPathToConfigFile();

export const versionNumber = custom.versionNumber;
export const versionCode = custom.versionCode;
export const packageId = custom.packageId;
export const termsOfServiceVersion = custom.termsOfServiceVersion;
export const iosStoreUrl = custom.iosStoreUrl;
export const androidStoreUrl = custom.androidStoreUrl;
export const scheme = custom.scheme;

const expoConfig: { expo: ExpoConfig } = {
  expo: {
    ...base.config.expo,
    ...custom.config.expo,
    plugins: [...base.config.expo.plugins, ...custom.config.expo.plugins],
  },
};

if (!process.env.LUCI_DEBUG) {
  expoConfig.expo.runtimeVersion = {
    policy: 'appVersion',
  };
}

// eslint-disable-next-line no-restricted-syntax
export default expoConfig;
