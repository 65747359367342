import React, { useMemo, useRef, useState } from 'react';
import { Dimensions, StyleSheet, View, Animated, Easing, DimensionValue } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useAppTheme } from '../../styles/theme';
import tinycolor from 'tinycolor2';

const SCREEN_WIDTH = Dimensions.get('screen').width;
const START = -1;
const END = 1;
const DURATION = 2000;
const LOCATIONS = [0.3, 0.5, 0.7];
const ANIMATION = new Animated.Value(START);

const runAnimation = () => {
  ANIMATION.setValue(START);
  Animated.timing(ANIMATION, {
    toValue: END,
    duration: DURATION,
    easing: value => Easing.linear(value),
    useNativeDriver: true,
  }).start(runAnimation);
};

const linear = ANIMATION.interpolate({
  inputRange: [START, END],
  outputRange: [-SCREEN_WIDTH, SCREEN_WIDTH],
});

runAnimation();

type ShimmerPropTypes = {
  width: DimensionValue;
  height: DimensionValue;
  radius?: number;
  color?: string;
};

const styles = StyleSheet.create({
  shimmer: {
    overflow: 'hidden',
    backgroundColor: '#eee',
  },
});

export const Shimmer = ({ width, height, radius, color }: ShimmerPropTypes) => {
  const theme = useAppTheme();
  const [positionX, setPositionX] = useState<number>(0);
  const viewRef = useRef<View | null>(null);
  const colors = useMemo(
    () => [
      color ?? 'transparent',
      color ? tinycolor(color).brighten().toHexString() : theme.customColors.lightRipple,
      color ?? 'transparent',
    ],
    [theme, color],
  );

  return (
    <View
      style={[
        styles.shimmer,
        {
          width,
          height,
          borderRadius: radius,
          backgroundColor: color ?? theme.customColors.lightRipple,
        },
      ]}
      ref={viewRef}
      onLayout={() => {
        if (viewRef.current) {
          viewRef.current.measure((_x, _y, _width, _height, pageX) => {
            setPositionX(pageX);
          });
        }
      }}
    >
      {positionX !== null && (
        <Animated.View
          style={{
            flex: 1,
            left: -positionX,
            transform: [{ translateX: linear }],
            borderRadius: radius,
          }}
        >
          <LinearGradient
            style={{ flex: 1, width: SCREEN_WIDTH, borderRadius: radius }}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            locations={LOCATIONS}
            colors={colors}
          />
        </Animated.View>
      )}
    </View>
  );
};
