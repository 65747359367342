import { useCallback, useEffect } from 'react';
import { useChatStore } from '../Store';

interface IUseCurrentChatRoomId {
  chatRoomId: string;
}

export const useCurrentChatRoomId = ({ chatRoomId }: IUseCurrentChatRoomId) => {
  const setCurrentChatRoomId = useChatStore(s => s.setCurrentChatRoomId);

  const syncChatRoomId = useCallback(() => {
    if (chatRoomId) {
      return setCurrentChatRoomId(chatRoomId);
    }
  }, [chatRoomId]);

  useEffect(() => {
    syncChatRoomId();
    return () => setCurrentChatRoomId(undefined);
  }, [chatRoomId]);
};
