import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Divider, Subheading } from 'react-native-paper';
import { OfflineErrorModal } from '../../../components/Common/Modals/OfflineErrorModal';
import { GetUserPreferenceQuery } from '../../../../graphql/operations';
import { LanguageSelectionButton } from './LanguageSelectionButton';
import { TranslationSwitch } from './TranslationSwitch';

type TranslationSettingsPropTypes = {
  preference: GetUserPreferenceQuery['getUserPreference'];
  userId: string;
  disabled: boolean;
  isRefetching: boolean;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: Platform.OS === 'web' ? 0 : 25,
  },
  divider: { marginVertical: 10 },
  innerDivider: { marginVertical: 4 },
});

export const TranslationSettings = ({
  preference,
  userId,
  disabled,
  isRefetching,
}: TranslationSettingsPropTypes) => {
  const [isDisabled, setDisabled] = useState(disabled);

  useEffect(() => {
    setDisabled(isRefetching || disabled);
  }, [disabled, isRefetching]);

  return (
    <OfflineErrorModal>
      {onOnline => (
        <>
          <View style={styles.container}>
            <Subheading>Übersetzungsoptionen</Subheading>
          </View>
          <Divider style={styles.divider} />
          <TranslationSwitch
            text="News-Übersetzungen aktivieren"
            permission
            preference={preference}
            userId={userId}
            onOnline={onOnline}
            isDisabled={isDisabled}
            setDisabled={setDisabled}
          />
          <View style={styles.innerDivider}></View>
          <LanguageSelectionButton
            text="Sprache"
            permission={preference.translationEnabled}
            preference={preference}
            onOnline={onOnline}
            disabled={isDisabled}
          />
        </>
      )}
    </OfflineErrorModal>
  );
};
