import { Platform } from 'react-native';
import * as Sentry from '@sentry/react-native';

type Extra = { message?: string; data?: any };

export class Log {
  static info = (input: any, extra: Extra = {}) => {
    if (Platform.OS !== 'web') {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(`INFO: ${input}`, { extra });
      }
    }
  };

  static warning = (input: any, extra: Extra = {}) => {
    if (Platform.OS !== 'web') {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(`WARNING: ${input}`, { extra });
      } else {
        Sentry.captureMessage(input, { extra, level: 'warning' });
      }
    }
  };

  static error = (input: any, extra: Extra = {}) => {
    if (Platform.OS !== 'web') {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(`ERROR: ${input}`);
      } else {
        Sentry.captureException(input, { extra });
      }
    }
  };

  static exceptionsOfCall = async <T>(
    func: (...args: T[]) => Promise<any>,
    message: string,
    ...args: T[]
  ) => {
    try {
      await func(...args);
    } catch (e) {
      this.error(e, { message });
    }
  };
}
