import NewsNavigator, { NewsNavigatorParamList } from './NewsNavigator';
import * as ScreenOrientation from 'expo-screen-orientation';
import * as Notifications from 'expo-notifications';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import { ContactsScreen } from '../../features/profiles/screens/ContactsScreen';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { MenuScreen } from '../../screens/MenuScreen';
import { NavigatorScreenParams } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Text, Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import {
  BottomTabBar,
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import { themeProp } from '../../types/main';
import { useTheme } from 'react-native-paper';
import {
  useIsUserMemberOfATeamQuery,
  useUnreadInquiriesCountQuery,
  useUnreadMessageCountQuery,
} from '../../../graphql/operations';
import { useSetBadgeCount } from '../../hooks/useSetBadgeCount/useSetBadgeCount';
import { useAppStatus } from '../../hooks/common/appStatus';
import { useQueryClient } from '@tanstack/react-query';
import { registerPushNotificationBackgroundTask } from '../../utils/registerPushNotificationBackgroundTask/registerPushNotificationBackgroundTask';
import { setUnreadMessageBadge } from '../../utils/registerPushNotificationBackgroundTask/setUnreadMessageBadge';
import { MenuScreenProps } from '../../features/chat/screens/types';
import { useStore } from '../../stores/store';
import { Log } from '../../utils/Log';

import { useBreakpoint } from '../../hooks';
import Animated, { FadeInUp, FadeOutDown } from 'react-native-reanimated';
import { ChatNavigator } from './ChatNavigator';
import { InquiryOverviewScreen } from '../../features/inquiries/screens';
import { useTenantContext } from '../../hooks/tenant';
import { AppBar } from '../../components/AppBar/AppBar';
import { TabBarTestId } from '../../../test/testIds';
import { useKeepSocketConnected } from '../../socket/useKeepSocketConnected';
import { useCreateNewsListener } from '../../socket/listeners/useCreateNewsListener';
import { useNewsCountListener } from '../../socket/listeners/useNewsCountListener';
import { useChatMessagesListener } from '../../socket/listeners/useChatMessagesListener';
import { useUnreadNewsStore } from '../../stores/useUnreadNewsStore';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: false,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const Tab = createBottomTabNavigator<HomeTabNavigatorParamList>();

const Stack = createStackNavigator<{
  MenuScreen: StackNavigationProp<HomeTabNavigatorParamList, 'Menu'>;
}>();

export type HomeTabNavigatorParamList = {
  News: NavigatorScreenParams<NewsNavigatorParamList>;
  Chats: undefined;
  Contacts: NavigatorScreenParams<{
    ContactsScreen: undefined;
  }>;
  Menu: NavigatorScreenParams<{
    MenuScreen: MenuScreenProps;
  }>;
  Inquiries: undefined;
};

const MenuScreenNavigator = () => (
  <Stack.Navigator
    initialRouteName={'MenuScreen'}
    screenOptions={{
      header: () => <AppBar title="Menü" />,
      headerMode: 'screen',
    }}
  >
    <Stack.Screen
      name="MenuScreen"
      options={{
        title: 'Menü',
        headerLeft: () => null,
      }}
      component={MenuScreen}
    />
  </Stack.Navigator>
);

export const HomeTabNavigator = () => {
  const { fetchTenantAndUpdateState } = useTenantContext();
  const unreadNewsCount = useUnreadNewsStore(s => s.count);
  const { appMovedToView } = useAppStatus();
  const queryClient = useQueryClient();
  const [bottomTabBarVisible, setBottomTabBarVisible] = useState(true);
  const { breakpoint } = useBreakpoint();
  const isSmallScreen = breakpoint === 'xs' || breakpoint === 'sm';
  const { userProfile } = useStore(s => ({
    userProfile: s.userProfile,
  }));
  const isUserMemberOfATeamQuery = useIsUserMemberOfATeamQuery(
    { userId: userProfile?.userId ?? '' },
    { enabled: !!userProfile?.userId },
  );

  useEffect(() => {
    if (isUserMemberOfATeamQuery.data && userProfile) {
      useStore.setState({
        userProfile: {
          ...userProfile,
          isUserMemberOfATeam: isUserMemberOfATeamQuery.data.isUserMemberOfATeam,
        },
      });
    }
  }, [isUserMemberOfATeamQuery.data?.isUserMemberOfATeam, userProfile?.userId]);

  useEffect(() => {
    setBottomTabBarVisible(isSmallScreen);
  }, [breakpoint]);

  const theme = useTheme() as themeProp;
  const unreadMessageCountQuery = useUnreadMessageCountQuery(undefined, {
    // refetchInterval: 30000,
  });
  const unreadMessageCount = unreadMessageCountQuery.data?.unreadMessageCount ?? 0;

  const unreadInquiriesCountQuery = useUnreadInquiriesCountQuery(undefined, {
    // refetchInterval: 30000,
  });
  const unreadInquiriesCount = unreadInquiriesCountQuery.data?.unreadInquiriesCount ?? 0;

  registerPushNotificationBackgroundTask(setUnreadMessageBadge(queryClient));

  useSetBadgeCount(unreadMessageCountQuery);

  const tenantName = useStore.getState().tenantName;

  const refreshUnreadMessageCount = async () => {
    try {
      await queryClient.invalidateQueries(useUnreadMessageCountQuery.getKey());
    } catch (e) {
      Log.warning(e, {
        message:
          'something went wrong when invalidating useUnreadMessageCountQuery in HomeTabNavigator',
      });
    }
  };

  const refreshUnreadInquiriesCount = async () => {
    try {
      await queryClient.invalidateQueries(useUnreadInquiriesCountQuery.getKey());
    } catch (e) {
      Log.warning(e, {
        message:
          'something went wrong when invalidating useUnreadInquiriesCountQuery in HomeTabNavigator',
      });
    }
  };

  const refreshTenantData = async (tenantNameArg: string) => {
    try {
      await fetchTenantAndUpdateState(tenantNameArg);
    } catch (e) {
      Log.warning(e, {
        message: 'something went wrong when calling fetchTenantAndUpdateState in HomeTabNavigator',
      });
    }
  };

  useEffect(() => {
    if (appMovedToView) {
      void (async function () {
        try {
          await Promise.all([
            refreshUnreadMessageCount(),
            refreshUnreadInquiriesCount(),
            refreshTenantData(tenantName),
            unreadMessageCountQuery.refetch(),
            unreadInquiriesCountQuery.refetch(),
            isUserMemberOfATeamQuery.refetch(),
          ]);
        } catch (e) {
          Log.error(e, { message: 'cannot refresh data from home tab' });
        }
      })();
    }
  }, [appMovedToView]);

  const unlockOrientation = async () => {
    try {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
    } catch (e) {
      Log.error(e);
    }
  };

  useFocusEffect(() => {
    if (Platform.OS !== 'web') {
      void unlockOrientation();
    }
  });

  useKeepSocketConnected();
  useCreateNewsListener();
  useNewsCountListener();
  useChatMessagesListener();

  const StyledTabBarLabel = (barProps: any) => (
    <Text
      style={{
        marginTop: barProps.position === 'beside-icon' ? 3 : -5,
        marginLeft: barProps.position === 'beside-icon' ? 20 : 0,
        marginBottom: barProps.position === 'beside-icon' ? 0 : 5,
        color: barProps.color,
        fontSize: 12,
      }}
    >
      {barProps.name}
    </Text>
  );

  return (
    <Tab.Navigator
      tabBar={props => (
        <Animated.View
          entering={FadeInUp}
          exiting={FadeOutDown}
          style={{
            height: bottomTabBarVisible ? undefined : 1,
            opacity: bottomTabBarVisible ? 1 : 0,
            backgroundColor: theme.colors.background,
          }}
        >
          <BottomTabBar {...props} />
        </Animated.View>
      )}
      initialRouteName="News"
      backBehavior="none"
      screenOptions={{
        headerShown: false,
        tabBarStyle: {
          backgroundColor: theme.customColors.background3,
        },
      }}
    >
      <Tab.Screen
        name="News"
        component={NewsNavigator}
        options={{
          title: 'News',
          tabBarLabel: (barProps: any) => <StyledTabBarLabel {...barProps} name="News" />,
          tabBarIcon: ({ color }) => <Icon name="home-outline" color={color} size={28} />,
          tabBarBadgeStyle: {
            backgroundColor: theme.customColors.primary,
          },
          tabBarBadge: unreadNewsCount === 0 ? undefined : unreadNewsCount,
        }}
      />
      <Tab.Screen
        name="Chats"
        component={ChatNavigator}
        options={
          {
            testId: TabBarTestId.chat,
            title: 'Chats',
            tabBarLabel: (barProps: any) => <StyledTabBarLabel {...barProps} name="Chats" />,
            tabBarIcon: ({ color }) => <Icon name="message-outline" color={color} size={24} />,
            tabBarBadgeStyle: {
              backgroundColor: theme.customColors.primary,
            },
            tabBarBadge: unreadMessageCount === 0 ? undefined : unreadMessageCount,
          } as BottomTabNavigationOptions & {
            testID?: string;
          }
        }
      />
      <Tab.Screen
        name="Contacts"
        component={ContactsScreen}
        options={{
          title: 'Kontakte',
          tabBarLabel: (barProps: any) => <StyledTabBarLabel {...barProps} name="Kontakte" />,
          tabBarIcon: ({ color }) => (
            <Icon name="account-multiple-outline" color={color} size={25} />
          ),
        }}
      />
      {userProfile?.isUserMemberOfATeam && (
        <Tab.Screen
          name="Inquiries"
          component={InquiryOverviewScreen}
          options={{
            title: 'Anfragen',
            tabBarLabel: (barProps: any) => <StyledTabBarLabel {...barProps} name="Anfragen" />,
            tabBarIcon: ({ color }) => <Icon name="list-status" color={color} size={25} />,
            tabBarBadgeStyle: {
              backgroundColor: theme.customColors.primary,
            },
            tabBarBadge: unreadInquiriesCount === 0 ? undefined : unreadInquiriesCount,
          }}
        />
      )}
      <Tab.Screen
        name="Menu"
        component={MenuScreenNavigator}
        options={{
          title: 'Menü',
          tabBarLabel: (barProps: any) => <StyledTabBarLabel {...barProps} name="Menü" />,
          tabBarIcon: ({ color }) => <Icon name="menu" color={color} size={28} />,
        }}
      />
    </Tab.Navigator>
  );
};
