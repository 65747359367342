import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View, ScrollView, StyleSheet, Platform } from 'react-native';
import { Divider } from 'react-native-paper';
import { useQueryClient } from '@tanstack/react-query';
import {
  TenantConfigurationQuery,
  useWorkScheduleIntegrationQuery,
} from '../../../graphql/operations';
import { MenuScreenProps } from '../../features/chat/screens/types';
import { useChatStore } from '../../features/chat/Store';
import { UserCard } from '../../features/profiles/components/UserCard';
import { useResponsiveDrawer } from '../../router/drawer/hooks/useResponsiveDrawer';
import { useStore } from '../../stores/store';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { retrieveTenant } from '../../utils/tenancyUtils';
import { EmptyCard } from '../Common/Empty/EmptyCard';
import { ModernMenuItem, ModernMenuItemType } from './ModernMenuItem';
import { useUploadPushNotificationToken } from '../../hooks';

type MenuContentPropTypes = {
  isRenderedInDrawer?: boolean;
};

export const MenuScreenContent = ({ isRenderedInDrawer }: MenuContentPropTypes) => {
  const queryClient = useQueryClient();
  const navigation = useNavigation<MenuScreenProps['navigation']>();
  const { userProfile, logout } = useStore(s => ({
    userProfile: s.userProfile,
    logout: s.logout,
    pushTokens: s.pushTokens,
  }));
  const [tenant, setTenant] = useState<TenantConfigurationQuery['tenantConfiguration']>();
  const { isRightDrawerOpen } = useResponsiveDrawer({});
  const notVisible = isRenderedInDrawer && !isRightDrawerOpen;
  const workScheduleIntegration = useWorkScheduleIntegrationQuery(
    {
      tenantName: tenant?.tenantName ?? '',
    },
    {
      enabled: !!tenant && !notVisible,
    },
  );
  const chatStore = useChatStore();
  const theme = useAppTheme();
  const styles = createStyles(theme);

  useEffect(() => {
    if (!tenant) {
      /* @todo must be awaited */
      void loadTenant();
    }
  }, []);

  useUploadPushNotificationToken();

  const loadTenant = async () => {
    const fetchTenantResult = await retrieveTenant();
    if (fetchTenantResult) {
      setTenant(fetchTenantResult);
    }
  };

  const items: Array<ModernMenuItemType> = [
    {
      icon: 'calendar-clock',
      title: 'Dienstplan',
      onPress: () => {
        navigation.navigate('ScheduleLoginVivendi', {
          endpoint: workScheduleIntegration.data!.workScheduleIntegration!.endpoint,
        });
      },
      hidden:
        !workScheduleIntegration.data?.workScheduleIntegration ||
        !workScheduleIntegration.data.workScheduleIntegration.provider ||
        Platform.OS === 'web',
      backgroundColor: theme.customColors.listRowItemBackground,
    },
    {
      icon: 'file-document',
      title: 'Infoseiten',
      onPress: () =>
        navigation.navigate('Home', {
          screen: 'App',
          params: { screen: 'Main', params: { screen: 'InfoPagesHome' } },
        }),
      backgroundColor: theme.customColors.listRowItemBackground,
    },
    {
      icon: 'format-list-checks',
      title: 'Formulare',
      onPress: () =>
        navigation.navigate('Home', {
          screen: 'App',
          params: { screen: 'Main', params: { screen: 'FormsHomeScreen' } },
        }),
      backgroundColor: theme.customColors.listRowItemBackground,
    },
    {
      icon: 'cog',
      title: 'Einstellungen',
      onPress: () =>
        navigation.navigate('Home', {
          screen: 'App',
          params: { screen: 'Main', params: { screen: 'Settings' } },
        }),
      backgroundColor: theme.customColors.listRowItemBackground,
    },
    {
      icon: 'comment-check-outline',
      title: 'Hilfe',
      onPress: () =>
        navigation.navigate('Home', {
          screen: 'App',
          params: { screen: 'Main', params: { screen: 'Help' } },
        }),
      backgroundColor: theme.customColors.listRowItemBackground,
    },
    {
      icon: 'information-outline',
      title: 'Informationen',
      onPress: () =>
        navigation.navigate('Home', {
          screen: 'App',
          params: { screen: 'Main', params: { screen: 'InformationScreen' } },
        }),
      backgroundColor: theme.customColors.listRowItemBackground,
      hidden: Platform.OS === 'web',
    },
  ];

  if (!isRenderedInDrawer && isRightDrawerOpen) {
    return (
      <EmptyCard
        iconName="menu"
        headlineText="Das Menü befindet sich auf der rechten Seite"
        messageText="Verwenden Sie den Abschnitt auf der rechten Seite, um eine Seite zu öffnen."
      />
    );
  }

  return (
    <View style={styles.mainContainer}>
      <View style={styles.header}>
        <UserCard
          touchable
          hasChevron
          rightAligned={isRightDrawerOpen}
          descriptionOverride="Profil bearbeiten"
          userId={userProfile?.userId}
          onPress={() => {
            navigation.navigate('Home', {
              screen: 'App',
              params: { screen: 'Main', params: { screen: 'ProfileEdit' } },
            });
          }}
          style={styles.userCard}
        />
      </View>
      {!isRightDrawerOpen && <Divider style={styles.topDivider} />}
      <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContainer}>
        {items.map((item, index) => (
          <ModernMenuItem item={item} key={index} />
        ))}
        <Divider style={styles.divider} />
        <ModernMenuItem
          item={{
            icon: 'exit-to-app',
            title: 'Abmelden',
            onPress: () => {
              void logout({ queryClientToClearCacheOf: queryClient, chatStoreToClear: chatStore });
            },
            backgroundColor: theme.customColors.listRowItemBackground,
          }}
        />
      </ScrollView>
    </View>
  );
};

const createStyles = (theme: AppTheme) =>
  StyleSheet.create({
    mainContainer: {
      height: '100%',
      backgroundColor: theme.colors.background,
    },
    scrollView: {
      paddingHorizontal: 16,
      backgroundColor: theme.colors.background,
    },
    scrollViewContainer: {
      paddingBottom: 30,
    },
    header: {
      marginVertical: 20,
      marginHorizontal: 16,
      backgroundColor: theme.colors.background,
    },
    divider: {
      marginTop: 20,
      backgroundColor: theme.customColors.borders,
    },
    topDivider: {
      backgroundColor: theme.customColors.borders,
      height: 1,
    },
    userCard: {
      paddingHorizontal: 12,
      paddingVertical: 12,
      minHeight: 0,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.18,
      shadowRadius: 1.0,
      elevation: 1,
      backgroundColor: theme.customColors.listRowItemBackground,
    },
  });
