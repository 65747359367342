import React, { useEffect } from 'react';
import { Image, ImageProps, Platform, ImageURISource } from 'react-native';

import { Log } from '../../utils/Log';

export const AutoHeightImage = React.memo(
  ({
    width,
    maxHeight,
    initialHeight,
    style = {},
    source,
    ...props
  }: ImageProps & { width: any; maxHeight?: number; initialHeight?: number }) => {
    const imgElement = React.useRef<HTMLDivElement>(null);
    const [height, setHeight] = React.useState(initialHeight ?? 1);
    const heightLimit = maxHeight ?? Infinity;
    const isOnWeb = Platform.OS === 'web';

    useEffect(() => {
      if (!isOnWeb && !initialHeight) {
        const uri = typeof source === 'object' ? (source as ImageURISource).uri : undefined;

        if (uri) {
          Image.getSize(
            uri,
            (sourceWidth, sourceHeight) => {
              const desiredHeight = (sourceHeight / sourceWidth) * width;
              setHeight(desiredHeight > heightLimit ? heightLimit : desiredHeight);
            },
            error => {
              Log.error('Error getting image size:', error);
            },
          );
        }
      }
    }, [source, width, heightLimit, isOnWeb, initialHeight]);

    const handleImageLoad = () => {
      if (isOnWeb && imgElement.current) {
        const img = imgElement.current?.getElementsByTagName('img')[0];
        if (!img) {
          return;
        }
        const naturalHeight = img.naturalHeight;
        const naturalWidth = img.naturalWidth;
        const desiredHeight = (naturalHeight / naturalWidth) * width;
        setHeight(desiredHeight > heightLimit ? heightLimit : desiredHeight);
      }
    };

    return (
      <Image
        {...props}
        source={source}
        style={[{ height, width }, style]}
        // @ts-ignore
        ref={imgElement}
        onLoad={handleImageLoad}
      />
    );
  },
);
