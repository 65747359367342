import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  TextInput as TextInputRn,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { RichTextEditor } from '../../components/RichTextEditor/RichTextEditor';
import { useRichTextEditor } from '../../components/RichTextEditor/hooks/useRichTextEditor';
import { RichTextEditorToolbar } from '../../components/RichTextEditor/components/RichTextEditorToolbar';
import { IconButton, Text } from 'react-native-paper';
import { AppNavigatorParamList } from '../../router/AppNavigator';
import { StackScreenProps } from '@react-navigation/stack';
import { AppTheme, useAppTheme } from '../../styles/theme';
import { useDebouncedValidate, usePopup, useShowErrorOnPromiseFalse } from '../../hooks';
import { DiscardDialog } from '../../components/Dialog/DiscardDialog';
import { useUserGroupsOfUser } from './hooks/useUserGroupsOfUser';
import { FullScreenModalPicker } from '../../components/Picker/FullScreenModalPicker';
import { NewsUserGroupLabels } from '../../components/News/NewsUserGroupLabels';
import { SwitchSetting } from '../../components/SwitchSetting/SwitchSetting';
import { File } from '../../components/FileGallery/types/';
import { ImageViewerModal } from '../../components/Common/Modals/ImageViewerModal';
import { useImageViewerModal } from '../../components/Common/Modals/hooks/useImageViewerModal';
import { DateTimeSetting } from '../../components/Common/DateTimeSetting/DateTimeSetting';
import { FileType } from '../../components/FileGallery/types';
import { SelectedForm, SelectedInfoPage } from '../../components/Common/Media/types';
import { useLinkList } from '../../components/Common/Media/hooks/useLinkList';
import { FormLinkList } from '../chat/components';
import { SelectLinksModal } from '../../components/SelectLinksModal';
import { useSelectLinksModal } from '../../components/SelectLinksModal/hooks/useSelectLinksModal';
import { usePreviewOverlay } from '../../components/PreviewOverlay/hooks/usePreviewOverlay';
import { Feature, UserType, useSingleNewsToEditQuery } from '../../../graphql/operations';
import { InfoPageLinkList } from '../chat/components/InfoPageLinkList';
import { NewsLinks } from '../../components/News/NewsLinks/NewsLinks';
import { PreviewOverlay } from '../../components/PreviewOverlay';
import { INITIAL_SNAP_POINT, NewsBottomSheet, TextInput } from './newsCreation/components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createNewsShape } from './utils/createNewsShape';
import htmlToMd from 'html-to-md';
import { useFileStore } from '../../stores/FileStore';
import { useIsKeyboardVisible } from '../../hooks/useIsKeyboardVisible/useIsKeyboardVisible';
import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import moment from 'moment';
import { useNewNewsRequests } from './hooks/useNewNewsRequests';
import { LoadingBlocker } from '../../components/Common/Loading/LoadingBlocker';
import { Popup } from '../../components/Popup';
import markdownIt from 'markdown-it';
import uuid from 'react-native-uuid';
import { maxContentAreaWidth } from '../../router/navigators/ContentNavigator';
import { getFileTypeFromOtcPath } from '../../utils/extensionToFileType';
import SwiperFlatList from 'react-native-swiper-flatlist';
import { TileFactory } from '../../components/Tiles/TileFactory';
import { IndexIndicator } from '../../components/Common/Content/IndexIndicator';
import { toast } from '../../utils/toast/toast';
import { FullScreenModal } from '../../components/FullScreenModal';
import { useAndroidBackHandler } from 'react-navigation-backhandler';

export const NewNewsScreen = ({
  navigation,
  route,
}: StackScreenProps<AppNavigatorParamList, 'NewNews'>) => {
  const theme = useAppTheme();
  const screenWidth = Dimensions.get('screen').width;
  const width = screenWidth > maxContentAreaWidth ? maxContentAreaWidth : screenWidth;
  const { keyboardHeight } = useIsKeyboardVisible();
  const styles = useMemo(
    () => createStyles(theme, width, keyboardHeight),
    [theme, width, keyboardHeight],
  );
  const isOnEditMode = !!route.params?.newsId;
  const titleRef = useRef<TextInputRn>(null);
  const [initialUserGroupIds, setInitialUserGroupIds] = useState<string[]>([]);

  const {
    ref: textEditorForNewsContentRef,
    isInitialized: isNewsContentInputInitialized,
    setInitialized: setNewsContentInputInitialized,
  } = useRichTextEditor();

  const blurEditors = () => {
    textEditorForNewsContentRef.current?.blurContentEditor();
    titleRef.current?.blur();
  };

  const { files, setFiles, clear: removeFiles } = useFileStore();

  const onPressEditAttachments = () => {
    blurEditors();
    navigation.navigate('AddMediaScreen', { files });
  };

  useAndroidBackHandler(() => {
    toggleConfirmDiscardDialog();
    return true;
  });

  const newsQuery = useSingleNewsToEditQuery(
    { newsId: route.params?.newsId ?? '' },
    { enabled: isOnEditMode },
  );

  const newsToEdit = newsQuery.data?.news;

  useEffect(() => {
    if (!isOnEditMode) {
      toggleSelectingUserGroups();
    }
  }, [isOnEditMode]);

  useEffect(() => {
    if (newsToEdit && newsToEdit.userGroupIds) {
      setInitialUserGroupIds(newsToEdit.userGroupIds);
    }
  }, [newsToEdit]);

  const {
    selections: userGroupIdSelections,
    options: userGroupOptions,
    selectionsBuffer: userGroupIdSelectionBuffer,
    isSelecting: isSelectingUserGroups,
    isInitialLoading: isUserGroupsLoading,
    labelsOfSelectedUserGroups,
    toggleSelecting: toggleSelectingUserGroups,
    syncSelectionsWithBuffer,
    resetBufferToSelections,
    onSelectionsBufferChange,
  } = useUserGroupsOfUser({
    shouldStoreBeSynced: false,
    shouldOptionsHaveEditorRole: true,
    initialSelections: initialUserGroupIds,
  });

  const {
    imageViewerModalImages,
    imageViewerModalVisible,
    setImageViewerModalImages,
    setImageViewerModalVisible,
  } = useImageViewerModal();

  const onFileGalleryTilePress = (file: File<unknown>) => {
    if (file.type === FileType.Image) {
      setImageViewerModalImages([{ url: file.uri }]);
      return setImageViewerModalVisible(true);
    }
  };

  const {
    append: appendInfoPageToList,
    remove: removeInfoPageFromList,
    selections: selectedInfoPages,
    setSelections: setInfoPageList,
    clear: clearSelectedInfoPages,
  } = useLinkList<SelectedInfoPage>();

  const {
    append: appendFormToList,
    remove: removeFormFromList,
    selections: selectedForms,
    setSelections: setFormList,
    clear: clearSelectedForms,
  } = useLinkList<SelectedForm>();

  const {
    isVisible: isSelectLinksModalVisible,
    modalTitle: selectLinksModalTitle,
    selectedFeature: selectLinksModalFeature,
    setSelectedFeature: setSelectLinksModalFeature,
    setVisible: setSelectLinksModalVisible,
  } = useSelectLinksModal();

  const {
    isVisible: isPreviewOverlayVisible,
    title: previewOverlayTitle,
    items: previewOverlayItems,
    reset: resetPreviewOverlay,
    setPreviewOverlayForFeature,
    removeItem: removeItemFromPreviewOverlay,
  } = usePreviewOverlay();

  const onPressSubmitSelectLinksModal = async () => {
    setSelectLinksModalVisible(false);
    const isFormsSelected = selectedForms.length > 0;
    if (isFormsSelected) {
      return setPreviewOverlayForFeature({
        feature: Feature.Form,
        recipientName: 'zu den News hinzufügen',
        items: selectedForms,
      });
    }
    const isInfoPagesSelected = selectedInfoPages.length > 0;
    if (isInfoPagesSelected) {
      return setPreviewOverlayForFeature({
        feature: Feature.Infopage,
        recipientName: 'zu den News hinzufügen',
        items: selectedInfoPages,
      });
    }
  };

  const isSubmitLinksDisabled =
    (selectLinksModalFeature === Feature.Infopage && selectedInfoPages.length === 0) ||
    (selectLinksModalFeature === Feature.Form && selectedForms.length === 0);

  const onRemoveItemFromPreviewOverlay = (itemId: string) => {
    removeItemFromPreviewOverlay(itemId);
    if (selectLinksModalFeature === Feature.Infopage) {
      return removeInfoPageFromList(itemId);
    }
    if (selectLinksModalFeature === Feature.Form) {
      return removeFormFromList(itemId);
    }
  };

  const onConfirmAddItemToNews = async () => {
    const fieldValue = previewOverlayItems.map(item => ({ id: item.itemId, title: item.title }));
    switch (selectLinksModalFeature) {
      case Feature.Infopage:
        await setFieldValue('infoPages', fieldValue);
        break;
      case Feature.Form:
        await setFieldValue('forms', fieldValue);
        break;
      default:
        break;
    }
    clearSelectedForms();
    clearSelectedInfoPages();
    resetPreviewOverlay();
  };

  const openFormPicker = () => {
    blurEditors();
    if (values.forms) {
      setFormList(values.forms);
    }
    setSelectLinksModalFeature(Feature.Form);
    setSelectLinksModalVisible(true);
  };

  const openInfoPagePicker = () => {
    blurEditors();
    if (values.infoPages) {
      setInfoPageList(values.infoPages);
    }
    setSelectLinksModalFeature(Feature.Infopage);
    setSelectLinksModalVisible(true);
  };

  const openMediaGallery = () => {
    blurEditors();
    navigation.navigate('AddMediaScreen', { files });
  };

  const openUserGroupSelectionModal = () => {
    blurEditors();
    toggleSelectingUserGroups();
  };

  const validationSchema = yup.object(createNewsShape);

  const mdToHtml = markdownIt({ html: true });

  const {
    createNews,
    updateNews,
    isLoading: isNewNewsRequestsLoading,
    isDownloadingAttachments,
  } = useNewNewsRequests({
    attachmentsToDownload: newsToEdit?.attachments ?? undefined,
    onAttachmentsDownloaded: attachments =>
      setFiles(
        attachments.map(attachment => ({
          id: uuid.v4().toString(),
          type: getFileTypeFromOtcPath(attachment.otcPath),
          uri: attachment.uri,
          optionalParams: { otcPath: attachment.otcPath },
        })),
      ),
  });

  const initialValues: yup.InferType<typeof validationSchema> = {
    title: '',
    content: '',
    userGroupIds: userGroupIdSelections,
    attachments: [],
    isLikesEnabled: true,
    isCommentsEnabled: true,
    isReadConfirmationsEnabled: false,
    isPinned: false,
    expiredAt: undefined,
    visibleAt: undefined,
    templateName: undefined,
    forms: undefined,
    infoPages: undefined,
    isTemplate: false,
    isPublished: true,
    tags: [],
    userTypes: [UserType.Employee],
  };

  const initialValuesOnEditMode: yup.InferType<typeof validationSchema> = {
    title: newsToEdit?.title ?? '',
    content: mdToHtml.render(newsToEdit?.content ?? ''),
    userGroupIds: newsToEdit?.userGroupIds ?? [],
    attachments: newsToEdit?.attachments ?? [],
    isLikesEnabled: newsToEdit?.settings.likesEnabled ?? true,
    isCommentsEnabled: newsToEdit?.settings.commentsEnabled ?? true,
    isReadConfirmationsEnabled: newsToEdit?.settings.readConfirmationsEnabled ?? false,
    isPinned: !!newsToEdit?.pinnedAt,
    expiredAt: newsToEdit?.expiredAt ?? undefined,
    visibleAt: newsToEdit?.visibleAt ?? undefined,
    templateName: undefined,
    forms: newsToEdit?.forms ?? undefined,
    infoPages: newsToEdit?.infoPages ?? undefined,
    isTemplate: false,
    isPublished: true,
    tags: newsToEdit?.tags?.map(tag => ({ label: tag, value: tag })) ?? [],
    userTypes: newsToEdit?.userTypes ?? [UserType.Employee],
  };

  const { errorPopup, showErrorOnPromiseFalse } = useShowErrorOnPromiseFalse();

  const onSuccessCreateNews = () => {
    removeFiles();
    navigation.goBack();
    toast('Die News wurde erfolgreich erstellt.');
  };

  const onSuccessUpdateNews = () => {
    removeFiles();
    navigation.goBack();
    toast('Die News wurde erfolgreich bearbeitet.');
  };

  const onSubmit = async ({
    isLikesEnabled,
    isCommentsEnabled,
    isReadConfirmationsEnabled,
    isPinned,
    userGroupIds,
    forms,
    infoPages,
    title,
    expiredAt,
    visibleAt,
  }: typeof initialValues) => {
    toggleConfirmPostDialog();
    const plainTextTitle = title;
    const markdownContent = htmlToMd(values.content)
      .trim()
      // this is a workaround this issue: https://github.com/iamacup/react-native-markdown-display/issues/165
      .replace(/\<u\>/gi, '[')
      .replace(/\<\/u\>/gi, ']()');
    if (isOnEditMode && newsToEdit?.id) {
      await showErrorOnPromiseFalse(
        updateNews({
          onSuccess: onSuccessUpdateNews,
          id: newsToEdit.id,
          userGroupIds,
          plainTextTitle,
          markdownContent,
          isLikesEnabled,
          isCommentsEnabled,
          isReadConfirmationsEnabled,
          isPinned,
          visibleAt,
          expiredAt,
          attachments: newsToEdit.attachments ?? undefined,
          files,
          formIds: forms?.map(form => form.id),
          infoPageIds: infoPages?.map(infoPage => infoPage.id),
        }),
        'Leider konnten die News nicht aktualisiert werden. Bitte versuchen Sie es erneut und kontaktieren Sie den LUCI-Support, falls das Problem weiterhin besteht.',
      );
      return;
    }
    await showErrorOnPromiseFalse(
      createNews({
        onSuccess: onSuccessCreateNews,
        userGroupIds,
        plainTextTitle,
        markdownContent,
        isLikesEnabled,
        isCommentsEnabled,
        isReadConfirmationsEnabled,
        isPinned,
        visibleAt,
        expiredAt,
        files,
        formIds: forms?.map(form => form.id),
        infoPageIds: infoPages?.map(infoPage => infoPage.id),
      }),
      'Leider konnten die News nicht erstellt werden. Bitte versuchen Sie es erneut und kontaktieren Sie den LUCI-Support, falls das Problem weiterhin besteht.',
    );
  };

  const { values, setFieldValue, isValid, handleChange, validateForm } = useFormik({
    initialValues: isOnEditMode ? initialValuesOnEditMode : initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: false,
    enableReinitialize: true,
  });

  useEffect(() => {
    void setFieldValue('userGroupIds', userGroupIdSelections);
  }, [userGroupIdSelections]);

  useDebouncedValidate({
    validate: formValues => {
      void validateForm(formValues);
    },
    debounceTime: 200,
    values,
  });

  const { popup: confirmDiscardDialog, toggleOpen: toggleConfirmDiscardDialog } = usePopup(
    'Sind Sie sicher?',
    'Ihre Änderungen gehen dann unwiderruflich verloren.',
  );

  const confirmPostDialogContent = isOnEditMode
    ? `Die von Ihnen vorgenommenen Änderungen können nicht rückgängig gemacht werden.`
    : values.visibleAt
    ? `Die News werden an folgendem Datum veröffentlicht:\n\n${moment(values.visibleAt).format(
        'D MMMM YYYY, HH:mm',
      )}`
    : `Sie werden diese News sofort veröffentlichen.`;

  const { popup: confirmPostDialog, toggleOpen: toggleConfirmPostDialog } = usePopup(
    'Sind Sie sicher?',
    confirmPostDialogContent,
  );

  const isPostNewsButtonDisabled =
    !isValid || values.content.length === 0 || values.title.length === 0;

  const { isKeyboardVisible } = useIsKeyboardVisible();

  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(0);

  const [isEditorsPrefilled, setEditorsPrefilled] = useState(false);

  const prefillEditorContents = () => {
    textEditorForNewsContentRef.current?.insertHTML(initialValuesOnEditMode.content);
    setEditorsPrefilled(true);
  };

  useEffect(() => {
    if (isOnEditMode && !newsQuery.isInitialLoading && !isEditorsPrefilled) {
      prefillEditorContents();
    }
  }, [isOnEditMode, !newsQuery.isInitialLoading, isEditorsPrefilled]);

  return (
    <FullScreenModal
      show={true}
      title={`News ${isOnEditMode ? 'bearbeiten' : 'erstellen'}`}
      isSubmitDisabled={isPostNewsButtonDisabled || isDownloadingAttachments}
      onClose={toggleConfirmDiscardDialog}
      onSubmit={toggleConfirmPostDialog}
      submitIcon={isOnEditMode ? 'content-save' : 'send'}
    >
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.scrollViewContainer}
        // fix for https://github.com/react-native-webview/react-native-webview/issues/2364
        overScrollMode="never"
        keyboardShouldPersistTaps="handled"
      >
        <TouchableWithoutFeedback onPress={blurEditors}>
          <View>
            <Text variant="titleMedium" style={styles.fieldLabel}>
              Organisationsbereich/e
            </Text>
            <View style={styles.userGroupsContainer}>
              <NewsUserGroupLabels
                isEditable
                isLoading={isOnEditMode ? newsQuery.isLoading : isUserGroupsLoading}
                userGroupLabels={labelsOfSelectedUserGroups}
                onPressEdit={openUserGroupSelectionModal}
              />
            </View>
            <TextInput
              onChangeText={handleChange('title')}
              value={values.title}
              placeholder="Wie lautet die Überschrift?"
              label="Titel"
              ref={titleRef}
            />
            <Text variant="titleMedium" style={styles.fieldLabel}>
              Text
            </Text>
          </View>
        </TouchableWithoutFeedback>
        <RichTextEditor
          editorRef={textEditorForNewsContentRef}
          initialContentHtml={
            isOnEditMode && !newsQuery.isInitialLoading
              ? initialValuesOnEditMode.content
              : undefined
          }
          setHtmlContent={handleChange('content')}
          setInitialized={setNewsContentInputInitialized}
          placeholder="Wie lautet der Text?"
        />
        <TouchableWithoutFeedback onPress={blurEditors}>
          <View>
            <Text variant="titleMedium" style={styles.fieldLabel}>
              Einstellungen
            </Text>
            <View style={styles.settingsContainer}>
              <SwitchSetting
                label="Likes"
                icon="thumb-up"
                value={values.isLikesEnabled}
                onValueChange={value => void setFieldValue('isLikesEnabled', value)}
              />
              <SwitchSetting
                label="Kommentare"
                icon="comment"
                value={values.isCommentsEnabled}
                onValueChange={value => void setFieldValue('isCommentsEnabled', value)}
              />
              <SwitchSetting
                label="Lesebestätigung"
                icon="eye-check"
                value={values.isReadConfirmationsEnabled}
                onValueChange={value => void setFieldValue('isReadConfirmationsEnabled', value)}
              />
              <SwitchSetting
                label="Gepinnt"
                icon="pin"
                value={values.isPinned}
                onValueChange={value => void setFieldValue('isPinned', value)}
              />
              <DateTimeSetting
                label="Veröffentlichung"
                icon="clock-start"
                value={values.visibleAt}
                unspecifiedValueText="Sofort"
                setValue={value => setFieldValue('visibleAt', value)}
              />
              <DateTimeSetting
                label="Ablaufzeitpunkt"
                icon="clock-end"
                value={values.expiredAt ?? undefined}
                unspecifiedValueText="Niemals"
                setValue={value => setFieldValue('expiredAt', value)}
              />
            </View>
            {files.length !== 0 && (
              <>
                <View style={styles.subtitleContainer}>
                  <Text variant="titleMedium" style={styles.fieldLabel}>
                    Medieninhalte
                  </Text>
                  <IconButton
                    icon="lead-pencil"
                    iconColor={theme.customColors.primary}
                    size={16}
                    onPress={onPressEditAttachments}
                    testID="edit-attached-media"
                  />
                  <IconButton
                    icon="close-thick"
                    iconColor={theme.customColors.primary}
                    size={16}
                    onPress={() => {
                      blurEditors();
                      removeFiles();
                    }}
                    testID="clear-attached-media"
                  />
                </View>
                {files.length > 0 && (
                  <View style={styles.mediaContainer}>
                    <SwiperFlatList
                      showPagination={files.length !== 1}
                      paginationStyle={styles.pagination}
                      paginationDefaultColor={theme.customColors.gray40}
                      paginationActiveColor={theme.customColors.pureWhite}
                      paginationStyleItem={styles.swiperItem}
                      paginationStyleItemInactive={styles.swiperItemInactive}
                      onChangeIndex={item => {
                        setCurrentAttachmentIndex(item.index);
                      }}
                    >
                      {files.map((file, i) => (
                        <TileFactory
                          key={i}
                          file={file}
                          width={width}
                          onTilePress={onFileGalleryTilePress}
                        />
                      ))}
                    </SwiperFlatList>
                    {files.length !== 1 && (
                      <IndexIndicator
                        currentIndex={currentAttachmentIndex}
                        numberOfItems={files.length}
                      />
                    )}
                  </View>
                )}
              </>
            )}
            {values.forms && values.forms.length !== 0 && (
              <>
                <View style={styles.subtitleContainer}>
                  <Text variant="titleMedium" style={styles.fieldLabel}>
                    Angehängte Formulare
                  </Text>
                  <IconButton
                    icon="lead-pencil"
                    iconColor={theme.customColors.primary}
                    size={16}
                    onPress={openFormPicker}
                    testID="edit-forms"
                  />
                  <IconButton
                    icon="close-thick"
                    iconColor={theme.customColors.primary}
                    size={16}
                    onPress={async () => {
                      blurEditors();
                      await setFieldValue('forms', []);
                    }}
                    testID="clear-forms"
                  />
                </View>
                <NewsLinks formLinks={values.forms} />
              </>
            )}
            {values.infoPages && values.infoPages.length !== 0 && (
              <>
                <View style={styles.subtitleContainer}>
                  <Text variant="titleMedium" style={styles.fieldLabel}>
                    Angehängte Infoseiten
                  </Text>
                  <IconButton
                    icon="lead-pencil"
                    iconColor={theme.customColors.primary}
                    size={16}
                    onPress={openInfoPagePicker}
                    testID="edit-infopages"
                  />
                  <IconButton
                    icon="close-thick"
                    iconColor={theme.customColors.primary}
                    size={16}
                    onPress={async () => {
                      blurEditors();
                      await setFieldValue('infoPages', []);
                    }}
                    testID="clear-infopages"
                  />
                </View>
                <NewsLinks infoPageLinks={values.infoPages} />
                <ImageViewerModal
                  visible={imageViewerModalVisible}
                  setVisible={setImageViewerModalVisible}
                  activeIndex={0}
                  imageUrls={imageViewerModalImages}
                />
              </>
            )}
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
      <NewsBottomSheet
        renderOnTop={() => (
          <RichTextEditorToolbar
            editorRef={textEditorForNewsContentRef}
            isEditorInitialized={isNewsContentInputInitialized}
          />
        )}
        openFormPicker={openFormPicker}
        openInfoPagePicker={openInfoPagePicker}
        openMediaGallery={openMediaGallery}
        onPressKeyboardAction={
          isKeyboardVisible
            ? () => blurEditors()
            : () => textEditorForNewsContentRef.current?.focusContentEditor()
        }
      />
      <Popup {...errorPopup.popup} />
      <DiscardDialog
        isOpen={confirmDiscardDialog.isOpen}
        title={confirmDiscardDialog.title}
        content={confirmDiscardDialog.text}
        onCancel={toggleConfirmDiscardDialog}
        onDiscard={() => {
          toggleConfirmDiscardDialog();
          removeFiles();
          navigation.goBack();
        }}
      />
      <ConfirmDialog
        isOpen={confirmPostDialog.isOpen}
        title={confirmPostDialog.title}
        content={confirmPostDialogContent}
        onCancel={toggleConfirmPostDialog}
        onConfirm={() => onSubmit(values)}
        confirmButtonLabel={isOnEditMode ? 'Weiter' : 'Posten'}
        cancelButtonLabel="Zurück"
      />
      <FullScreenModalPicker<string>
        isVisible={isSelectingUserGroups}
        title="Organisationsbereiche"
        description="Bitte wählen Sie den/die Organisationsbereich/e aus, in dem/denen dieser Newsbeitrag
        veröffentlicht werden soll."
        onClose={resetBufferToSelections}
        options={userGroupOptions}
        selectionsBuffer={userGroupIdSelectionBuffer}
        onSelectionBufferChange={onSelectionsBufferChange}
        isConfirmSelectionDisabled={userGroupIdSelectionBuffer.length === 0}
        onPressConfirmSelection={syncSelectionsWithBuffer}
        submitIcon="content-save-move-outline"
        renderOptionLabel={option => (
          <Text style={styles.optionListItemLabel} numberOfLines={1}>
            {option.label}
          </Text>
        )}
      />
      <PreviewOverlay
        items={previewOverlayItems}
        show={isPreviewOverlayVisible}
        title={previewOverlayTitle}
        onCancel={() => {
          clearSelectedForms();
          clearSelectedInfoPages();
          resetPreviewOverlay();
        }}
        onConfirm={onConfirmAddItemToNews}
        removeItem={onRemoveItemFromPreviewOverlay}
        confirmIcon="content-save-move-outline"
      />
      {selectLinksModalTitle && (
        <SelectLinksModal
          show={isSelectLinksModalVisible}
          title={selectLinksModalTitle}
          onPressCancel={() => setSelectLinksModalVisible(false)}
          onPressSubmit={onPressSubmitSelectLinksModal}
          disableSubmitButton={isSubmitLinksDisabled}
        >
          {selectLinksModalFeature === Feature.Infopage && (
            <InfoPageLinkList
              append={appendInfoPageToList}
              remove={removeInfoPageFromList}
              selectedInfoPageIds={values.infoPages?.map(infoPage => infoPage.id)}
            />
          )}
          {selectLinksModalFeature === Feature.Form && (
            <FormLinkList
              append={appendFormToList}
              remove={removeFormFromList}
              selectedFormIds={values.forms?.map(form => form.id)}
            />
          )}
        </SelectLinksModal>
      )}
      <LoadingBlocker visible={isNewNewsRequestsLoading || isDownloadingAttachments} />
    </FullScreenModal>
  );
};

const createStyles = (theme: AppTheme, width: number, keyboardHeight: number) =>
  StyleSheet.create({
    scrollView: {
      height: '100%',
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderColor: theme.customColors.borders,
    },
    scrollViewContainer: {
      paddingBottom: INITIAL_SNAP_POINT + (Platform.OS === 'ios' ? keyboardHeight : 0),
    },
    keyboardAvoidingView: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    header: {
      fontSize: 18,
      marginRight: -42,
      fontWeight: 'bold',
      textAlign: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    userGroupsContainer: {
      width: '100%',
      padding: 10,
      paddingTop: 2,
    },
    fieldLabel: { padding: 10, paddingBottom: 2 },
    settingsContainer: {
      marginTop: 8,
      marginHorizontal: 16,
    },
    subtitleContainer: {
      flexDirection: 'row',
    },
    mediaContainer: {
      flex: 1,
      width: width,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderBottomColor: theme.customColors.borders,
      borderTopColor: theme.customColors.borders,
      backgroundColor: theme.customColors.background3,
      paddingTop: 10,
      paddingBottom: 4,
      marginLeft: -1,
    },
    error: {
      color: theme.customColors.error,
      marginTop: 3,
    },
    container: {
      width: '100%',
    },
    pagination: {
      height: 10,
    },
    swiperItem: {
      borderWidth: 3,
      borderColor: theme.customColors.gray10,
      width: 12,
      height: 12,
      marginHorizontal: 12,
    },
    swiperItemInactive: { width: 12, height: 12, opacity: 0.5 },
    optionListItemLabel: {
      color: theme.customColors.text,
      fontWeight: 'normal',
      padding: 5,
    },
  });
