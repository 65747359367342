import { useTransition, useCallback, useState } from 'react';
import { useChatMutations } from './useChatMutations';
import {
  ChatRoomAsOption,
  UserProfileAsOption,
} from '../../../components/Picker/FullScreenModalPicker';
import { Constants } from '../../../utils';

export interface IForwardMessages {
  chatMessageIds: string[];
  forwardedTo: {
    chatRoomIds: ChatRoomAsOption['id'][];
    contactIds: UserProfileAsOption['userId'][];
  };
  onSuccess?: (numberOfFailedDeliveries: number) => void;
}

export type MessageForwardModalProps = {
  isVisible: boolean;
  onDismiss(): void;
  forwardMessages: ({ chatMessageIds, forwardedTo, onSuccess }: IForwardMessages) => Promise<void>;
};

export const useForwardMessages = () => {
  const { forwardMessages: forwardMessagesMutation, isForwardingMessages } = useChatMutations();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isLimitAlertVisible, setLimitAlertVisible] = useState(false);
  const [, startTransition] = useTransition();

  const forwardMessages = useCallback(
    async ({ chatMessageIds, forwardedTo, onSuccess }: IForwardMessages) => {
      setIsConfirmModalVisible(false);
      if (!chatMessageIds || chatMessageIds.length === 0) {
        return onSuccess?.(0);
      }
      const totalMessages =
        (forwardedTo.chatRoomIds.length + forwardedTo.contactIds.length) * chatMessageIds.length;
      if (totalMessages > Constants.FORWARDED_MESSAGE_COUNT_LIMIT) {
        return setLimitAlertVisible(true);
      }
      await forwardMessagesMutation({ chatMessageIds, forwardedTo, onSuccess });
    },
    [],
  );

  const showConfirmModal = useCallback(() => {
    startTransition(() => {
      setIsConfirmModalVisible(true);
    });
  }, []);

  const onDismiss = useCallback(() => setIsConfirmModalVisible(false), []);

  const confirmModalProps: MessageForwardModalProps = {
    isVisible: isConfirmModalVisible,
    forwardMessages,
    onDismiss,
  };

  return {
    confirmModalProps,
    showConfirmModal,
    isForwardingMessages,
    isLimitAlertVisible,
    setLimitAlertVisible,
  };
};
