import React from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { Button } from 'react-native-paper';
import { useAppTheme } from '../../styles/theme';

export interface SubmitButtonProps {
  disabled?: boolean;
  buttonStyle?: ViewStyle;
  text: string;
  onPress(): void;
  buttonProps?: Partial<React.ComponentProps<typeof Button>>;
}

const styles = StyleSheet.create({
  customButton: {
    justifyContent: 'center',
    alignSelf: 'center',
    width: 200,
  },
  loginButton: {
    borderRadius: 5,
    marginBottom: 4,
    height: 45,
  },
});

export const PrimaryButton = ({
  onPress,
  text,
  buttonStyle,
  disabled = false,
  buttonProps = {},
}: SubmitButtonProps) => {
  const theme = useAppTheme();
  return (
    <Button
      uppercase={false}
      disabled={disabled || buttonProps.loading}
      mode="contained"
      style={[styles.loginButton, styles.customButton, buttonStyle]}
      onPress={onPress}
      {...buttonProps}
      textColor={theme.customColors.textWhite}
    >
      {text}
    </Button>
  );
};
